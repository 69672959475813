const base = [
  {
    path: '/refund-manager',
    name: 'IadeYonetimi',
    component: () => import(/* webpackChunkName: "IadeYonetimi" */ '@/views/RefundManagement/RefundManager.vue'),
    meta: {
      title:  'IadeYonetimi',
      layout: 'AppLayoutDefault',
      auth: 'RefundManager.ShowPage'
    }
  }
];

export default base;
