const oldTranslates = localStorage.getItem('translates-en')
import enLocale from 'element-ui/lib/locale/lang/en'

let translates = {
  //login için
  AlanAdi: 'Domain',
  KullaniciAdi: 'Username',
  Sifre: 'Password',
  GirisYap: 'Login',
  BilgilerimiHatirla: 'Remember Me',
  GuvenlikKodu: 'Güvenlik Kodu',
  OTPBosOlamaz: 'OTP Boş Olamaz',
  GecerliSifreGiriniz: 'Lütfen geçerli bir şifre giriniz',
  GecerliDomainGiriniz: 'Lütfen geçerli bir domain giriniz',
  GecerliKullaniciAdiGiriniz: 'Lütfen geçerli bir kullanıcı adı giriniz',
  Turkce: 'Turkish',
  Ingilizce: 'English',
  ...enLocale
}


try {
  JSON.parse(oldTranslates).forEach(element => {
    translates[element.key] = element.value
  });
} catch (error) {
  // console.log(error)  
}


export default translates