<template>
  <div class="error-page-wrapper">
    <el-empty description="401">
      <p>{{ description }}</p>
      <el-button type="primary" @click="goHome">{{ $t('AnasayfayaDon') }}</el-button>
    </el-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {
      description: this.$t('BuSayfayaYetkinizYok')
    }
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'Anasayfa' })
    }
  },
}
</script>

<style lang="scss">
.error-page-wrapper {
  text-align: center;

  .el-empty__description {
    margin-top: 5px;

    p {
      font-size: 59px;
      color: #000;
      font-weight: bold;
    }
  }

  .el-empty__bottom {
    p {
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 300;
      color: #909399;
    }
  }
}
</style>