import Vue from 'vue'
import { MessageBox, Message } from 'element-ui';
import i18n from '@/locales' 
import router from "@/router"

const state = () => ({
  currentShelfId: null,
  shelfDetail: {
    definition: null,
    code: '',
    barcode: '',
    rank: '',
    isMissingProductShelf: false,
    isLotShelf: false,
    parentId: null,
    isOpenForSale: true
  },
  shelfBarcode: null,
  // productList: [],
  detailedProductList: false,
  shelfStock: null
})

const getters = {}

const mutations = {
  resetShelfDetail(state) {
    state.shelfDetail = {
      definition: null,
      code: '',
      barcode: '',
      rank: '',
      isMissingProductShelf: false,
      isLotShelf: false,
      parentId: null,
      isOpenForSale: true
    }
  },
  setShelfDetail(state, payload) {
    state.shelfDetail = payload
    state.shelfBarcode = payload.barcode
  },
  setCurrentShelfId(state, payload) {
    state.currentShelfId = payload
  },
  setProductList(state, payload) {
    state.productList = payload
  },
  chnageProductListUi(state, payload) {
    state.detailedProductList = payload
  },
  setShelfStock(state, payload) {
    state.shelfStock = payload
  }
}

const actions = {
  async getShelfDetail({ commit }, id) {
    try {
      const response = await Vue.prototype.$axios.get('/api/shelfs/'+id)
      commit('setShelfDetail', response.data)
      commit('setCurrentShelfId', response.data.id)
      response.data.subUnitList == 0 ? commit('chnageProductListUi', true) : commit('chnageProductListUi', false)
    } catch (error) {
      if (error.response.data.Key == 'SHELF_NOT_FOUND') {
        MessageBox.alert(i18n.t(error.response.data.Key, { id: error.response.data.Params.id }), i18n.t('AradiginizSayfaBulunamadi'), {
          center: true,
          showClose: false,
          type: 'warning',
          confirmButtonText: i18n.t('RafListesineDon'),
          callback: action => {
            router.push({name: 'RafListesi'})
          }
        })
      }
      throw error
    }
  },
  async removeShelf({}, id) {
    try {
      const response = await Vue.prototype.$axios.delete('/api/shelfs/'+ id)
      if (response.data.isError) return
      Message({ message: i18n.t('RafBasariylaSilindi'), type: 'success' })
    } catch (error) {
      throw error
    }
  },
  async createNewShelf({}, data) {
    try {
      const response = await Vue.prototype.$axios.post('/api/shelfs/', data)
      if (response.data.isError) return
      Message({ message: i18n.t('RafBasariylaEklendi'), type: 'success' })
    } catch (error) {
      throw error
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}