import Vue from 'vue'
import i18n from '@/locales' 

const state = () => ({
  //son 24 saat siparişleri
  last24hOrders: null,
  last24hOrdersChart: [[], []],
  last24hOrdersVisibilty: false,
  //güncel sipariş sayısı chart
  allOrdersChart: {},
  allOrdersChartVisibilty: false,
  //size özel
  userCounts: {
    order: {},
    control: {},
  },
  //set verileri
  mainStatistics: {},
  //sipariş kaynakları
  orderSources: {
    labels: [],
    data: [],
  },
  orderSourcesChartVisibilty: false,
  //bugün toplanan
  generalStatistics: {
    agentProductAndOrderTodayOrderTotal: 0,
    agentProductAndOrderTodayProductTotal: 0,
    qualityControlDashboardTodayCount: 0,
  },
  //araba verileri
  carUsingCounts: {},
  carChartVisibilty: false,
  //mal kabul
  goodsAccept: [[], []],
  //toplama süresi
  operationSpeed: null,
  //depoalr arası transfer
  watehouseTransferData: {},
});

const getters = {
  allOrdersChartData(state) {
    return state.allOrdersChart.items;
  },
};

const mutations = {
  setLast24hOrders(state, payload) {
    state.last24hOrders = payload;
    payload.chartData.map((item) => {
      state.last24hOrdersChart[0].push(item.oneProductOrderCount);
      state.last24hOrdersChart[1].push(item.aLotsOfProductOrderCount);
    });
    state.last24hOrdersVisibilty = true;
  },
  setAllOrderChartData(state, payload) {
    const findMixedInfo = payload.items.findIndex(
      (item) => item.type == "MixedProductOrder"
    );
    state.allOrdersChart = payload;
    state.allOrdersChart.productCount =
      payload.items[findMixedInfo].productCount;
    state.allOrdersChart.items.splice(findMixedInfo, 1);
    state.allOrdersChart.items.map((item) => {
      item.type = i18n.t(item.type);
    });
    state.allOrdersChartVisibilty = true;
    return;
  },
  setUserCounts(state, payload) {
    Object.assign(state.userCounts, payload);
  },
  setMainStatistics(state, payload) {
    state.mainStatistics = payload;
  },
  setOrderSources(state, payload) {
    state.orderSources.pureData = payload;
    state.orderSources.labels = [];
    state.orderSources.data = [];
    payload.map((item) => {
      state.orderSources.labels.push(item.orderSource);
      state.orderSources.data.push(item.orderCount);
    });
    state.orderSourcesChartVisibilty = true;
  },
  setGeneralStatistics(state, payload) {
    state.generalStatistics = payload;
  },
  setCarUsingCounts(state, payload) {
    state.carUsingCounts = payload;
    state.carChartVisibilty = true;
  },
  setGoodsAccept(state, payload) {
    state.goodsAccept = [[], []];
    payload.map((item) => {
      state.goodsAccept[0].push(item.statusName);
      state.goodsAccept[1].push(item.count);
    });
  },
  setWarehouseTransferData(state, payload) {
    state.watehouseTransferData = payload;
  },
};

const actions = {
  async getLast24hOrders({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/order-counts"
      );
      commit("setLast24hOrders", await response.data.model);
    } catch (error) {
      throw error;
    }
  },
  async getAllOrders({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/pickable-order"
      );
      commit("setAllOrderChartData", response.data);
    } catch (error) {
      throw error;
    }
  },
  async getAgentTotalOrderStatistics({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/pick-personal-statics"
      );
      commit("setUserCounts", { order: response.data.model });
    } catch (error) {
      throw error;
    }
  },
  async getAgentTotalQualityStatistics({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/quality-personal-statics"
      );
      commit("setUserCounts", { control: response.data.model });
    } catch (error) {
      throw error;
    }
  },
  async getMainStatistics({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/route-map"
      );
      commit("setMainStatistics", response.data.model);
    } catch (error) {
      throw error;
    }
  },
  async getOrderSources({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/order-sources"
      );
      commit("setOrderSources", response.data.model);
    } catch (error) {
      throw error;
    }
  },
  async getTodayGeneralStatistic({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/warehouse-statics"
      );
      commit("setGeneralStatistics", response.data.model);
    } catch (error) {
      throw error;
    }
  },
  async getCarUsingCounts({ commit }) {
    try {
      const response1 = await Vue.prototype.$axios.get(
        "/api/WarehouseCar/GetCount?Status=1&isActive=true"
      ); //boştaki arabalar
      const response2 = await Vue.prototype.$axios.get(
        "/api/WarehouseCar/GetCount?Status=2&isActive=true"
      ); //kullanılan arabalar

      commit("setCarUsingCounts", [response2.data.model, response1.data.model]);
    } catch (error) {
      throw error;
    }
  },
  async getGoodsAcceptData({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/GoodsAcceptStatusGetCount"
      );
      commit("setGoodsAccept", response.data.model);
    } catch (error) {
      throw error;
    }
  },
  async getOperationSpeed({ state }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/operation-speeds"
      );
      state.operationSpeed = response.data.model;
      // commit('setGoodsAccept',response.data.model)
    } catch (error) {
      throw error;
    }
  },
  // DEPOLAR ARASI TRASNFER
  async getWarehouseTransferData({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/warehouse-product-transfer"
      );
      commit("setWarehouseTransferData", response.data);
    } catch (error) {
      throw error;
    }
  },

  //CACHE TEMİZLEME
  async resetDashboardCache() {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/Dashboard/reset-cache"
      );
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}