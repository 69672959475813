<template>
  <div class="line-statistic-wrapper">
    <div class="graphics" v-if="mainStatistics.totalOrderPickedCount">
      <div class="progress-item">
        <div class="inner">
          <div class="name">{{ $t('Toplanan') }} <b>{{ $t('Siparis') }}</b></div>
          <div class="count">
            {{ mainStatistics.totalOrderPickingCount }}<span>/{{ mainStatistics.totalOrderPickedCount }}</span>
          </div>
          <div class="per">{{ parseFloat((mainStatistics.totalOrderPickingCount * 100 /
            mainStatistics.totalOrderPickedCount).toFixed(1)) || 0 }}</div>
        </div>
        <el-progress :stroke-width="12" type="circle" :color="customColorOrder" :text-inside="false" :width="136"
          :show-text="false"
          :percentage="parseFloat((mainStatistics.totalOrderPickingCount * 100 / mainStatistics.totalOrderPickedCount).toFixed(1))">
        </el-progress>
      </div>
      <div class="progress-item">
        <div class="inner">
          <div class="name">{{ $t('Toplanan') }} <b>{{ $t('Urun') }}</b></div>
          <div class="count">
            {{ mainStatistics.totalOrderProductPickingCount }}<span>/{{
              mainStatistics.totalOrderProductPickedCount }}</span>
          </div>
          <div class="per">{{ parseFloat((mainStatistics.totalOrderProductPickingCount * 100 /
            mainStatistics.totalOrderProductPickedCount).toFixed(1)) || 0 }}</div>
        </div>

        <el-progress :stroke-width="12" type="circle" :text-inside="false" :show-text="false" :width="136"
          :color="customColorProduct"
          :percentage="parseFloat((mainStatistics.totalOrderProductPickingCount * 100 / mainStatistics.totalOrderProductPickedCount).toFixed(1))"></el-progress>
      </div>
    </div>
    <div class="list">
      <div class="item">
        {{ $t('ArabadaBekleyenSayisi') }}
        <div class="count">{{ mainStatistics.totalCarWaitingProductCount }}</div>
      </div>
      <div class="item">
        {{ $t('MasadaBekleyenSayisi') }}
        <div class="count">{{ mainStatistics.totalWaitingTableCount }}</div>
      </div>
      <div class="item">
        {{ $t('FaturaKesilecekSayisi') }}
        <div class="count">{{ mainStatistics.totalQualityControlCount }}</div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      customColorOrder: [
        { color: '#FF8002', percentage: 99 },
        { color: '#00C753', percentage: 100 }
      ],
      customColorProduct: [
        { color: '#1AC8FF', percentage: 99 },
        { color: '#00C753', percentage: 100 }
      ]
    }
  },
  computed: {
    mainStatistics() {
      return this.$store.state.dashboard.mainStatistics
    },
  },
  methods: {
    productPerc() {
      if (this.mainStatistics.totalOrderProductPickedCount != 0) {
        return parseFloat((this.mainStatistics.totalOrderProductPickingCount * 100 / this.mainStatistics.totalOrderProductPickedCount).toFixed(1))
      } else {
        return 0
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.line-statistic-wrapper {
  flex: 2 1 32%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background: var(--box-color);
  border-radius: var(--box-radius);
  padding: 30px 21px;

  @include screen-down(xxl) {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .list {
    display: grid;
    gap: 10px;

    @include screen-down(xxl) {
      width: 100%;
    }

    @include screen-between(lg, xxl) {
      grid-template-columns: repeat(3, 1fr);
    }

    .item {
      background: white;
      border-radius: 12px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400;
      color: $color-1;
      gap: 10px;

      @include screen-up(xxl) {
        gap: 60px;
      }

      @include screen-between(lg, xxl) {
        gap: 10px;
      }

      .count {
        letter-spacing: -0.72px;
        font-size: 24px;
        font-weight: 800;
      }
    }

  }


  .graphics {
    display: flex;
    gap: 22px;

    // flex-shrink: 0;
    .progress-item {
      position: relative;

      .inner {
        width: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .name {
          letter-spacing: -0.24px;
          color: $color-1;
          font-size: 12px;
          line-height: calc(17/12);
          margin-bottom: 4px;

          b {
            display: block;
          }
        }

        .count {
          font-weight: 800;
          font-size: 23px;
          letter-spacing: -0.69px;

          span {
            font-size: 16px;
          }
        }

        .per {
          margin-top: 6px;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: -0.36px;
          color: #8993A4;

          &::before {
            content: "%";
          }
        }
      }
    }
  }


}

.el-progress {
  width: 100%;
}
</style>