const base = [
  {
    path: '/warehouse-management/shelf-list',
    name: 'RafListesi',
    component: () => import(/* webpackChunkName: "RafListesi" */ '@/views/WarehouseManagement/ShelfList.vue'),
    meta: {
      title: 'RafListesi',
      layout: 'AppLayoutDefault',
      auth: 'WarehouseManagement.ShelfManager.ShowPage'
    }
  },
  {
    path: '/warehouse-management/shelf-detail',
    name: 'RafDetay',
    component: () => import(/* webpackChunkName: "RafDetay" */ '@/views/WarehouseManagement/ShelfDetail.vue'),
    meta: {
      title: 'RafDetay',
      layout: 'AppLayoutNoBg',
      auth: 'WarehouseManagement.ShelfManager.EditShelf'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.id) {
        next()
      } else {
        next({name: 'RafListesi'})
      }
    }
  },
  {
    path: '/warehouse-management/car-list',
    name: 'ArabaListesi',
    component: () => import(/* webpackChunkName: "ArabaListesi" */ '@/views/WarehouseManagement/CarList.vue'),
    meta: {
      title: 'TumArabalar',
      layout: 'AppLayoutDefault',
      auth: 'WarehouseManagement.CarManager.ShowPage'
    }
  },
  {
    path: '/warehouse-management/representer-manager',
    name: 'TemsilciListesi',
    component: () => import(/* webpackChunkName: "TemsilciListesi" */ '@/views/WarehouseManagement/PersonnelList.vue'),
    meta: {
      title: 'TemsilciListesi',
      layout: 'AppLayoutDefault',
      auth: 'WarehouseManagement.RepresenterManager.ShowPage'
    }
  },
  {
    path: '/warehouse-management/table-list',
    name: 'MasaListesi',
    component: () => import(/* webpackChunkName: "MasaListesi" */ '@/views/WarehouseManagement/TableList.vue'),
    meta: {
      title: 'TumMasalar',
      layout: 'AppLayoutDefault',
      auth: 'WarehouseManagement.TableManager.ShowPage'
    }
  },
  {
    path: '/warehouse-management/table-detail',
    name: 'MasaDetay',
    component: () => import(/* webpackChunkName: "MasaDetay" */ '@/views/WarehouseManagement/TableDetail.vue'),
    meta: {
      title: 'MasaDetay',
      layout: 'AppLayoutNoBg',
      auth: 'WarehouseManagement.TableManager.AddTable'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.id) {
        next()
      } else {
        next({name: 'MasaListesi'})
      }
    }
  },
  {
    path: '/warehouse-management/authority-manager',
    name: 'YetkiYonetimi',
    component: () => import(/* webpackChunkName: "YetkiYonetimi" */ '@/views/WarehouseManagement/AuthorityManager.vue'),
    meta: {
      title: 'YetkiYonetimi',
      layout: 'AppLayoutDefault',
      auth: 'WarehouseManagement.AuthorityManager.ShowPage'
    }
  },
  {
    path: '/warehouse-management/supplier-manager',
    name: 'TedarikciYonetimi',
    component: () => import(/* webpackChunkName: "TedarikciYonetimi" */ '@/views/WarehouseManagement/SupplierManager.vue'),
    meta: {
      title: 'TedarikciYonetimi',
      layout: 'AppLayoutDefault',
      auth: 'WarehouseManagement.SupplierManager.ShowPage'
    }
  },
  {
    path: '/warehouse-management/customer-manager',
    name: 'MusteriYonetimi',
    component: () => import(/* webpackChunkName: "MusteriYonetimi" */ '@/views/WarehouseManagement/CustomerManager.vue'),
    meta: {
      title: 'MusteriYonetimi',
      layout: 'AppLayoutDefault',
      auth: 'WarehouseManagement.CustomerManager.ShowPage'
    }
  },
];

export default base;
