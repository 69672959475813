<template>
  <div class="chart-wrapper">
    <div class="chart-header">
      <div class="row">
        <h3>{{ $t('DepolarArasiTransfer') }} <span>({{ $t('Son1Hafta') }})</span></h3>
      </div>
    </div>
    <div class="chart-inner">
      <Transition name="fade" mode="out-in">
        <Bar v-if="chartVisible" :options="chartOptions" :data="chartData" />
        <el-skeleton :rows="12" animated v-else />
      </Transition>
    </div>
    <Transition name="fade" mode="out-in">
      <div class="chart-footer" v-if="chartVisible">
        <div class="chart-information">
          <div class="chart-info--item" v-for="(item, index) in chartData.datasets" :key="item.type">
            <div class="content">
              <h4>{{ item.label }}</h4>
              <h5>{{ item.data.toLocaleString().replace(',', '') }}</h5>
            </div>
          </div>
          
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  components: { Bar },
  data() {
    return {
      chartOptions: {
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        },
      },
    }
  },
  computed: {
    chartVisible() {
      return this.$store.state.dashboard.allOrdersChartVisibilty
    },
    allOrdersData() {
      return this.$store.getters['dashboard/watehouseTransferData']
    },
    chartData() {
      return {
        labels: ['', ''],
        datasets: [
          {
            label: 'Bekleyen Talep Dosyası',
            data: [this.$store.state.dashboard.watehouseTransferData.waitingFileCount, null],
            backgroundColor: '#A1A9B7',
          },
          {
            label: 'Tamamlanan Talep Dosyası',
            data: [this.$store.state.dashboard.watehouseTransferData.completedFileCount,null],
            backgroundColor: '#8EEBBF',
          },
          {
            label: 'Toplanan Ürün Sayısı',
            data: [null, this.$store.state.dashboard.watehouseTransferData.collectedProductCount],
            backgroundColor: '#99E7FF',
          },
          {
            label: 'Toplanacak Ürün Sayısı',
            data: [null, this.$store.state.dashboard.watehouseTransferData.waitingProductCount],
            backgroundColor: '#FFCC99',
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  flex: 1.4;
  min-width: 33%;

  @include screen-up(xl) {
    min-width: 550px;
  }
  @include screen-down(lg) {
    max-width: 100%;
    flex: 1 0 100%;
    width: 100%;
  }
}
// .chart-information {
//   @include screen-down(xxl){
//     flex-wrap: wrap !important;
//   }
// }
</style>