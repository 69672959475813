
import Vue from 'vue'
import { decrypt } from '@/helpers/utils/encrypt'

const base = [
  {
    path: "/settings/warehouse-settings",
    name: "DepoAyarlari",
    component: () =>
      import(
        /* webpackChunkName: "DepoAyarlari" */ "@/views/Settings/WarehouseSettings.vue"
      ),
    meta: {
      title: "DepoAyarlari",
      layout: "AppLayoutDefault",
      auth: "Settings.WarehouseSettings.ShowPage",
    },
  },
  {
    path: "/settings/my-account",
    name: "Hesabim",
    component: () =>
      import(
        /* webpackChunkName: "Hesabim" */ "@/views/Settings/MyAccount.vue"
      ),
    meta: {
      title: "Hesabim",
      layout: "AppLayoutNoBg",
      auth: "Settings.MyAccount.ShowPage",
    },
  },
  {
    path: "/settings/cargo-settings",
    name: "KargoAyarlari",
    component: () =>
      import(
        /* webpackChunkName: "KargoAyarlari" */ "@/views/Settings/CargoSettings.vue"
      ),
    meta: {
      title: "KargoAyarlari",
      layout: "AppLayoutDefault",
      auth: "Settings.WarehouseSettings.ShowPage",
    },
  },
  {
    path: "/settings/invoice-design",
    name: "FaturaTasarimi",
    component: () =>
      import(
        /* webpackChunkName: "FaturaTasarimi" */ "@/views/Settings/InvoiceDesignList.vue"
      ),
    meta: {
      title: "FaturaTasarimi",
      layout: "AppLayoutDefault",
      auth: "Settings.InvoiceDesign",
    },
  },
  {
    path: "/settings/invoice-design/:id",
    name: "FaturaTasarimiDetay",
    component: () =>
      import(
        /* webpackChunkName: "FaturaTasarimiDetay" */ "@/views/Settings/InvoiceDesignDetail.vue"
      ),
    meta: {
      title: "FaturaTasarimi",
      layout: "AppLayoutNoBg",
      auth: "Settings.InvoiceDesign",
    },
  },
  {
    path: "/settings/excel-jobs",
    name: "ExcelIsleri",
    component: () =>
      import(
        /* webpackChunkName: "ExcelIsleri" */ "@/views/Settings/ExcelJobs.vue"
      ),
    meta: {
      title: "ExcelIsleri",
      layout: "AppLayoutDefault",
      auth: "ProcessManagement.ExcelProcess",
    },
  },
  {
    path: "/settings/ticimax-settings",
    name: "TicimaxAyarlari",
    component: () =>
      import(
        /* webpackChunkName: "TicimaxAyarlari" */ "@/views/Settings/TicimaxSettings.vue"
      ),
    meta: {
      title: "TicimaxAyarlari",
      layout: "AppLayoutDefault",
    },
    beforeEnter: (to, from, next) => {
      if (JSON.parse(decrypt(Vue.$cookies.get("tcmxwmsuser"))).isTicimaxUser) {
        next();
      } else {
        next({ name: "401Page" });
      }
    },
  },
  {
    path: "/settings/store-settings",
    name: "MagazaYonetimi",
    component: () =>
      import(
        /* webpackChunkName: "MagazaYonetimi" */ "@/views/Settings/StoreSettings.vue"
      ),
    meta: {
      title: "MagazaYonetimi",
      layout: "AppLayoutDefault",
      auth: "Settings.StoreManagement",
    },
  },
  {
    path: "/icons",
    name: "Ikonlar",
    component: () =>
      import(/* webpackChunkName: "Ikonlar" */ "@/components/Icons/Index.vue"),
    meta: {
      layout: "AppLayoutDefault",
    },
    beforeEnter: (to, from, next) => {
      if (JSON.parse(decrypt(Vue.$cookies.get("tcmxwmsuser"))).isTicimaxUser) {
        next();
      } else {
        next({ name: "Anasayfa" });
      }
    },
  },
  // {
  //   path: '/settings/otp-setup',
  //   name: 'OTPSetup',
  //   component: () => import(/* webpackChunkName: "OTPSetup" */ '@/views/Settings/OTPSetup.vue'),
  //   meta: {
  //     title: 'OTPSetup',
  //     layout: 'AppLayoutDefault'
  //   }
  // },
];

export default base;
