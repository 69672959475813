<template>
  <div class="home">
    <div class="home-row">
      <welcome-box></welcome-box>
      <today-statistics
        v-if="$hasPermission('Dashboard.TotalQualityControlAndCollectedProductWidget')"></today-statistics>
      <main-statistics v-if="$hasPermission('Dashboard.ActiveSetsStatistics')"></main-statistics>
    </div>
    <div class="home-row">
      <last-24-hours-order-chart v-if="$hasPermission('Dashboard.OrderCountChart') && !isMobile"/>
      <total-order-chart v-if="$hasPermission('Dashboard.OrderCountChart') && !isMobile"/>
      <order-sources-chart v-if="$hasPermission('Dashboard.OrderSourceChart')"/>
      <warehouse-transfer-chart v-if="$hasPermission('Dashboard.WarehouseProductTransfer')"/>
      <car-statistics v-if="$hasPermission('Dashboard.UsedCarsChart')"/>
      <div class="home-column" style="max-width: min-content;"
        v-if="$hasPermission('Dashboard.PersonalStatistics') || $hasPermission('Dashboard.OrderOperationSpeedWidget')">
        <operation-speed v-if="$hasPermission('Dashboard.OrderOperationSpeedWidget')"></operation-speed>
        <personal-statistics v-if="$hasPermission('Dashboard.PersonalStatistics')"></personal-statistics>
      </div>
    </div>
    
  </div>
</template>

<script>
import WelcomeBox from '@/components/Home/WelcomeBox.vue';
import MainStatistics from '@/components/Home/MainStatistics.vue';
import PersonalStatistics from '@/components/Home/PersonalStatistics.vue';
import TodayStatistics from '@/components/Home/TodayStatistics.vue';
import Last24HoursOrderChart from '@/components/Home/Last24HoursOrderChart.vue';
import TotalOrderChart from '@/components/Home/TotalOrderChart.vue';
import OrderSourcesChart from '@/components/Home/OrderSourcesChart.vue';
import CarStatistics from '@/components/Home/CarStatistics.vue';
import OperationSpeed from '@/components/Home/OperationSpeed.vue'
import WarehouseTransferChart from '@/components/Home/WarehouseTransferChart.vue';


export default {
  components: {
    WelcomeBox,
    MainStatistics,
    PersonalStatistics,
    TodayStatistics,
    Last24HoursOrderChart,
    TotalOrderChart,
    OrderSourcesChart,
    CarStatistics,
    OperationSpeed,
    WarehouseTransferChart
  },
  name: "Home",
  beforeMount() {
    this.$store.commit("app/changeAxiosLoadingVisibilty", false) //loading kapatmak için
  },
  mounted() {
    document.body.classList.add('home-page')
    //ilk istek hata gelirse direkt panelden atsın diye ayrı alana yazıldı
    try {
      if (this.$hasPermission('Dashboard.OrderCountChart')) this.$store.dispatch('dashboard/getLast24hOrders')
      if (this.$hasPermission('Dashboard.OrderCountChart')) this.$store.dispatch('dashboard/getAllOrders')
    } catch (error) {
      console.log(error)
      return
    }
    try {
      if (this.$hasPermission('Dashboard.PersonalStatistics')) this.$store.dispatch('dashboard/getAgentTotalOrderStatistics')
      if (this.$hasPermission('Dashboard.PersonalStatistics')) this.$store.dispatch('dashboard/getAgentTotalQualityStatistics')
      if (this.$hasPermission('Dashboard.ActiveSetsStatistics')) this.$store.dispatch('dashboard/getMainStatistics')
      if (this.$hasPermission('Dashboard.OrderSourceChart')) this.$store.dispatch('dashboard/getOrderSources')
      if (this.$hasPermission('Dashboard.TotalQualityControlAndCollectedProductWidget')) this.$store.dispatch('dashboard/getTodayGeneralStatistic')
      if (this.$hasPermission('Dashboard.UsedCarsChart')) this.$store.dispatch('dashboard/getCarUsingCounts')
      if (this.$hasPermission('Dashboard.OrderOperationSpeedWidget')) this.$store.dispatch('dashboard/getOperationSpeed')
      if (this.$hasPermission('Dashboard.WarehouseProductTransfer')) this.$store.dispatch('dashboard/getWarehouseTransferData')
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    this.$store.commit("app/changeAxiosLoadingVisibilty", true)
    document.body.classList.remove('home-page')
  }
}
</script>
<style lang="scss" >
@import "@/assets/scss/pages/home";
</style>
