const oldTranslates = localStorage.getItem('translates-tr')
import trLocale from 'element-ui/lib/locale/lang/tr-TR'

let translates = {
  MagazaYonetimi: 'MagazaYonetimi*',
  MagazaSilEminMisiniz: 'Mağazayı silmek istediğinize emin misiniz?',
  Depolar: 'Depolar',
  //login için
  AlanAdi: 'Alan Adı',
  KullaniciAdi: 'Kullanıcı Adı',
  Sifre: 'Şifre',
  GirisYap: 'Giriş Yap',
  BilgilerimiHatirla: 'Bilgilerimi Hatırla',
  GuvenlikKodu: 'Güvenlik Kodu',
  OTPBosOlamaz: 'OTP Boş Olamaz',
  GecerliSifreGiriniz: 'Lütfen geçerli bir şifre giriniz',
  GecerliDomainGiriniz: 'Lütfen geçerli bir domain giriniz',
  GecerliKullaniciAdiGiriniz: 'Lütfen geçerli bir kullanıcı adı giriniz',
  Turkce: 'Türkçe',
  Ingilizce: 'İngilizce',
  ...trLocale
}


try {
  JSON.parse(oldTranslates).forEach(element => {
    translates[element.key] = element.value
  });
} catch (error) {
  // console.log(error)  
}


export default translates