import Vue from 'vue'
import i18n from '@/locales'

Vue.filter('textUpparcase', value=> {
  if (!value) return ''
  return value.toLocaleUpperCase(i18n.locale)
})

Vue.filter('timestampToDate', value=> {
  if (!value) return ''
  return new Date(Number(value)).toLocaleString(i18n.locale, {dateStyle: 'long' ,timeStyle: 'short'})
})
Vue.filter('timestampToDateShort', value=> {
  if (!value) return ''
  return new Date(Number(value)).toLocaleString(i18n.locale, {dateStyle: 'long'})
})
Vue.filter('timestampToTime', value=> {
  if (!value) return ' '
  const days = Math.floor(value / (24*60*60*1000));
  const daysms = value % (24*60*60*1000);
  const hours = Math.floor(daysms / (60*60*1000));
  const hoursms = value % (60*60*1000);
  const minutes = Math.floor(hoursms / (60*1000));
  let result = ''
  if (days != 0 ) result+= days + " gün "
  if (hours != 0) result+= hours + " saat "
  if (minutes != 0) result+=  minutes + " dakika"
  return result
})

Vue.filter('moneyFormat', value=> {
  if (!value) return '0,00'
  return new Intl.NumberFormat(i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
})