<template>
  <div class="chart-wrapper">
    <div class="chart-header">
      <div class="row">
        <h3>{{ $t('GuncelSiparisSayisi') }} <span>(Toplanabilir)</span></h3>
      </div>
    </div>
    <div class="chart-inner">
      <Transition name="fade" mode="out-in">
        <Bar v-if="chartVisible" :options="chartOptions" :data="chartData" />
        <el-skeleton :rows="12" animated v-else />
      </Transition>
    </div>
    <Transition name="fade" mode="out-in">
      <div class="chart-footer" v-if="chartVisible">
        <div class="chart-information">
          <div class="chart-info--item" v-for="(item, index) in allOrdersData" :key="item.type">
            <div class="icon">
              <component :is="iconList[index]"></component>
            </div>
            <div class="content">
              <h4>{{ item.type }}</h4>
              <h5>{{ item.orderCount.toLocaleString() }}
                <span>{{ item.productCount.toLocaleString() }} {{ $t('Urun') }}</span>
              </h5>
            </div>
          </div>
          <div class="chart-info--item total-chart-info">
            <div class="content">
              <h4>{{ $t('ToplamSiparis') }}</h4>
              <h5>{{ totalOrderCount.toLocaleString() }} <span>{{ totalProductCount.toLocaleString() }} {{ $t('Urun')
              }}</span>
              </h5>
            </div>
          </div>
        </div>
        <!-- <div class="total-chart-info">
          
        </div> -->
      </div>
    </Transition>
  </div>
</template>

<script>
import ShoppingCart from '@/components/Icons/IconShoppingCart.vue'
import ShoppingCart2 from '@/components/Icons/IconShoppingCart2.vue'
import ShoppingCart3 from '@/components/Icons/IconShoppingCart3.vue'

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  components: { Bar, ShoppingCart, ShoppingCart2, ShoppingCart3 },
  data() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },
        parsing: {
          xAxisKey: 'type',
          yAxisKey: 'orderCount'
        }
      },
      iconList: [
        'ShoppingCart',
        'ShoppingCart2',
        'ShoppingCart3'
      ]
    }
  },
  computed: {
    chartVisible() {
      return this.$store.state.adminDashboard.allOrdersChartVisibilty
    },
    totalOrderCount() {
      return this.$store.state.adminDashboard.allOrdersChart.orderCount
    },
    totalProductCount() {
      return this.$store.state.adminDashboard.allOrdersChart.productCount
    },
    allOrdersData() {
      return this.$store.getters['adminDashboard/allOrdersChartData']
    },
    chartData() {
      return {
        datasets: [{
          data: this.$store.getters['adminDashboard/allOrdersChartData'],
          backgroundColor: ['#8EEBBF', '#D0D4DB', '#80E1FF'],
          borderRadius: 6,
          borderWidth: 0
        }]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  flex: 3 0 45%;
  max-width: calc(50% - 5px);

  @include screen-down(lg) {
    max-width: 100%;
    flex: 1 0 100%;
    width: 100%;
  }
}
</style>