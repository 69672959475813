import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import login from './modules/login'
import app from './modules/app'
import picking from './modules/picking'
import setup from './modules/setup'
import warehouseTransfer from './modules/warehouse-product-transfer'
import qualityControl from './modules/quality-control'
import orderDetail from './modules/order-detail'
import shelfs from './modules/shelf-detail'
import dashboard from './modules/dashboard'
import adminDashboard from './modules/admin-dashboard'
import orderAssembly from './modules/order-assembly'
import refund from './modules/refund'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    app,
    picking,
    setup,
    warehouseTransfer,
    qualityControl,
    orderDetail,
    shelfs,
    login,
    dashboard,
    adminDashboard,
    orderAssembly,
    refund
  }
})
