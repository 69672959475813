import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

export function encrypt(data) {
  if (data) 
    return Vue.CryptoJS.AES.encrypt(data, "TcmxEncryptKey").toString()
  else 
    return ''
}
export function decrypt(data) {
  if (data) 
    return Vue.CryptoJS.AES.decrypt(data, "TcmxEncryptKey").toString(Vue.CryptoJS.enc.Utf8)
  else 
    return ''
}