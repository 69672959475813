<template>
  <div class="today-information">
    <div class="compare-box-item">
      <div class="day-box">
        <span class="icon"><icon-basket></icon-basket></span>
        <h3>{{ $t('BugunToplanan') }} <b> {{ $t('Siparis') }}</b></h3>
        <div class="count"> {{ generalStatistics.agentProductAndOrderTodayOrderTotal.toLocaleString() }} </div>
      </div>
      <div class="day-box">
        <span class="icon"><icon-product-tshirt></icon-product-tshirt></span>
        <h3>{{ $t('BugunToplanan') }} <b> {{ $t('Urun') }}</b></h3>
        <div class="count"> {{ generalStatistics.agentProductAndOrderTodayProductTotal.toLocaleString() }} </div>
      </div>
      <div class="day-box">
        <span class="icon"><icon-check-circle></icon-check-circle></span>
        <h3>{{ $t('BugunYapilan') }} <b>{{ $t('KaliteKontrol') }}</b></h3>
        <div class="count"> {{ generalStatistics.qualityControlDashboardTodayCount.toLocaleString() }} </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBasket from '@/components/Icons/IconBasket2.vue';
import IconProductTshirt from '@/components/Icons/IconProductTshirt.vue';
import IconCheckCircle from '@/components/Icons/IconCheckCircle.vue';

export default {
  components: {
    IconBasket,
    IconProductTshirt,
    IconCheckCircle,
  },
  computed: {
    generalStatistics() {
      return this.$store.state.adminDashboard.generalStatistics
    }
  },
}
</script>

<style lang="scss" scoped>
.today-information {
  flex: 1 0 29%;
  padding: 10px;
  background: var(--box-color);
  border-radius: var(--box-radius);
  display: flex;
  align-items: center;

  .compare-box-item {
    display: flex;
    align-items: flex-start;
    width: 100%;

    @include screen-between(lg, xxl) {
      // flex-wrap: wrap;
      justify-content: center;
      gap: 15px 0;
    }
  }

  .day-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: $color-1;
    width: 33.333%;

    @include screen-between(lg, xxl) {
      width: 50%;
    }

    h3 {
      font-size: 14px;
      line-height: calc(20/14);
      font-weight: 400;
      letter-spacing: -0.14px;

      @include screen-between(lg, xxl) {
        font-size: 12px;
      }

      b {
        display: block;
      }
    }

    .icon {
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #000;
      color: #fff;

      svg {
        width: 18px;
      }
    }

    .count {
      font-size: 42px;
      font-weight: 800;
      letter-spacing: -1.26px;

      @include screen-between(lg, xxl) {
        font-size: 34px;
      }
    }

    &:nth-child(1) {
      .icon {
        background: #1AC8FF;
      }
    }

    &:nth-child(2) {
      .icon {
        background: #00C753;
      }
    }

    &:nth-child(3) {
      .icon {
        background: #FF8002;
      }
    }
  }
}
</style>