<template>
  <div class="chart-wrapper">
    <div class="chart-header">
      <div class="row">
        <h3>{{ $t('SiparisKaynaklari') }}<span> ({{ $t('Son1Ay') }})</span></h3>
      </div>
    </div>
    <div class="chart-inner">
      <Transition name="fade" mode="out-in">
        <Pie v-if="chartVisible" :options="chartOptions" :data="chartData" />
        <el-skeleton :rows="5" animated v-else />
      </Transition>
    </div>
    <Transition name="fade" mode="out-in">
      <div class="chart-information  pasta-information" v-if="chartVisible">
        <div class="chart-info--item" v-for="(item, index) in allOrdersData" :key="item.type">
          <div class="icon" :style="{ backgroundColor: chartData.datasets[0].backgroundColor[index] }"></div>
          <div class="content">
            <h4>{{ item.orderSource }}</h4>
            <h5>{{ item.orderCount.toLocaleString() }}</h5>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
export default {
  components: { Pie },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: false
        }
      },
    }
  },
  computed: {
    chartVisible() {
      return this.$store.state.adminDashboard.orderSourcesChartVisibilty
    },
    allOrdersData() {
      return this.$store.state.adminDashboard.orderSources.pureData
    },
    chartData() {
      return {
        labels: this.$store.state.adminDashboard.orderSources.labels,
        datasets: [{
          backgroundColor: ['#A1A9B7', '#FFCC99', '#FFA7B3', '#8EEBBF', '#99E7FF'],
          data: this.$store.state.adminDashboard.orderSources.data,
          borderWidth: 0
        }]
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  flex: 1;
  max-width: 35%;
  @include screen-up(xxl) {
    max-width: 25%;
  }

  @include screen-down(md) {
    max-width: 100%;
  }
}
</style>