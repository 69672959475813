<template>
  <div class="operation-speed">
    <div class="icon"></div>
    <h4 class="title">{{ $t('OrtalamaHazirlamaSuresi') }}</h4>
    <div class="time" v-html="timeConvert(operationSpeed)"></div>
  </div>
</template>

<script>


export default {
  components: {
    
  },
  computed: {
    operationSpeed() {
      return this.$store.state.adminDashboard.operationSpeed
    }
  },
  methods: {
    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return `<b>${rhours}</b> sa <b>${rminutes}</b> dk`
    },
  }
}
</script>

<style lang="scss" >
.operation-speed {
  background: var(--box-color);
  border-radius: var(--box-radius);
  padding: 27px 22px;
  position: relative;
  display: flex;
  align-items: center;

  @include screen-between(lg, xxl) {
    padding: 27px 12px;
  }

  h4 {
    letter-spacing: -0.16px;
    color: $color-1;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding-right: 15px;

    @include screen-between(lg, xxl) {
      font-size: 13px;
      padding-right: 10px;
    }
  }

  .time {
    letter-spacing: -0.72px;
    color: $color-1;
    font-size: 24px;
    white-space: nowrap;
    margin-left: auto;
    padding-left: 15px;
    font-weight: 500;

    b {
      font-size: 34px;
      font-weight: 800;
    }
  }

  .icon {
    width: 38px;
    height: 38px;
    background: #00C753;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    svg {
      width: 18px;
    }
  }
}
</style>