export async function beep(freq) {
  if (!JSON.parse(localStorage.getItem('voiceWarn'))) return
  var a = new AudioContext();
  var v = a.createOscillator();
  var u = a.createGain();
  v.connect(u);
  if(freq == 'yes') {
    freq = 570
  } else if(freq == 'no') {
    freq = 170
  }
  v.frequency.value = freq;
  v.type = 'square';
  u.connect(a.destination);
  u.gain.value = 1;
  v.start(a.currentTime);
  v.stop(a.currentTime + 150 * 0.001);
}

const synth = window.speechSynthesis;
export async function voiceRead(word) {
  if (!JSON.parse(localStorage.getItem('voiceWarn'))) return
  let utterThis = new SpeechSynthesisUtterance(word);
  
  utterThis.lang = 'tr-TR';
  utterThis.pitch = '.1';
  utterThis.rate = '.4';
  utterThis.volume = 1;

  synth.speak(utterThis);
}