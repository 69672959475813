const base = {
  computed: {
    isMobile() { //storedan gelen datayı kullanmak için
      return this.$store.state.app.app.isMobile
    }
  },
  methods: {
    async createFilteredData() { //filtreleme olan sayfalarda default filtre ayarlama
      let defaultFilter = this.savedFilterList.filter(item=> item.isDefault)
      this.filterSchema.map((resp) => {
        this.filterList = {...this.filterList, [resp.model]: resp.value }
      });
      if (defaultFilter.length > 0) {
        Object.assign(this.filterList, JSON.parse(defaultFilter[0].filters))
      }
      return true
    }
  }
}
export default base