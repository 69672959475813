import printJS from 'print-js'
import { axiosInstance } from '@/plugins/axiostwo'
import { Message  } from 'element-ui';



export function printHtmlInvoice(printdata) {
  const deviceWidth = window.innerWidth
  if (deviceWidth < 1000) {
    printJS({ printable: printdata, type: 'raw-html' })
  } else {
    var iframe = document.createElement('iframe');
    var html = printdata;
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html);
    iframe.contentWindow.document.close();
    setTimeout(() => {
      iframe.remove();
    }, 500);
  }
  saveLastPrint({
    type: 'html',
    model: printdata
  })
}

export async function printHtml(printdata) {
  var iframe = document.createElement('iframe');
  var html = printdata;
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.contentWindow.document.open();
  iframe.contentWindow.document.write('<html moznomarginboxes mozdisallowselectionprint>');
  iframe.contentWindow.document.write('<body><style type="text/css" media="print">@page {size: auto;margin: 0;}</style>');
  iframe.contentWindow.document.write(html);
  iframe.contentWindow.document.write('</body></html>');
  iframe.contentWindow.print();
  iframe.contentWindow.document.close();
  setTimeout(() => {
    iframe.remove();
  }, 500);
}

export async function printElementTable(mainDiv) {
  let printDiv = mainDiv.cloneNode(true)
  printDiv.querySelector('.el-table__body-wrapper table').prepend(printDiv.querySelector('.el-table__header-wrapper thead'))

  printDiv.querySelectorAll('colgroup').forEach(element => {
    element.remove()
  });
  printDiv.querySelectorAll('th').forEach(element => {
    element.style.padding = "10px"
  });
  printDiv.querySelectorAll('td').forEach(element => {
    element.style.padding = "5px 10px"
    if (element.querySelector('.el-button')) {
      element.style.display = "none"
      printDiv.querySelectorAll('th')[printDiv.querySelectorAll('th').length - 2].style.display = "none"
    }
  });
  try {
    printDiv.querySelectorAll('.el-image__inner').forEach(element => {
      element.style.width = "50px"
    });
  } catch (error) {
    
  }
  printDiv.querySelectorAll('table').forEach(element => {
    element.removeAttribute('style');
    element.border = 1
    element.width = "100%"
    element.style.textAlign = "center"
    element.style.borderCollapse = "collapse"
    element.style.fontSize = "13px"
  });
  printHtml(printDiv.innerHTML)
}

export async function printOrderPdf(model) {
  return new Promise(resolve => {
    if (model.orderHtml != null) {
      printHtmlInvoice(model.orderHtml)
    } else {
      printJS({printable: model.orderPDF, type: 'pdf', base64: true})
      saveLastPrint({
        type: 'pdf',
        model: model.orderPDF
      })
    }
    resolve();
  });
 
}

export async function printQualityPdfCustomPrinter(model, userId) {
  await checkLocalService(true)
    try {
      if (model.cargoHtml != null) {
        const theme = await axiosInstance.get('/api/invoice-themes', { params: {
          IsDefault: true,
          Type: 'Cargo'
        }})
        const getUser = theme.data.model[0].customPrinterMatches.find(item => item.userId == userId)
        await localPrintHtmlHandler(theme.data.model[0].settings, model.cargoHtml, getUser.printerName, 'Kargo')
      }
      if (model.orderHtml != null) { 
        const theme = await axiosInstance.get('/api/invoice-themes', { params: {
          IsDefault: true,
          Type: 'Invoice'
        }})
        const getUser = theme.data.model[0].customPrinterMatches.find(item => item.userId == userId)
        await localPrintHtmlHandler(theme.data.model[0].settings, model.orderHtml, getUser.printerName, 'Fatura')
      }
      if(model.invoicePDF != null) {
        const theme = await axiosInstance.get('/api/invoice-themes', { params: {
          IsDefault: true,
          Type: 'OrderPdf'
        }})
        const getUser = theme.data.model[0].customPrinterMatches.find(item => item.userId == userId)
        await localPrintPdfHandler(theme.data.model[0].settings, model.orderHtml, getUser.printerName, 'Fatura')
      } 
      if (model.giftPackageHtml != null) {
        const theme = await axiosInstance.get('/api/invoice-themes', { params: {
          IsDefault: true,
          Type: 'GiftPackage'
        }})
        const getUser = theme.data.model[0].customPrinterMatches.find(item => item.userId == userId)
        await localPrintHtmlHandler(theme.data.model[0].settings, model.giftPackageHtml, getUser.printerName, 'Hediye Notu')
      }
    } catch (error) {
      Message({type: 'error', message: 'Yazıcıdan Çıktı Alınamadı. Lütfen Ticimax Destek ile iletişime geçiniz.'})
    }
}

export async function printQualityPdf(model) {
  return new Promise(resolve => {
    if (model.cargoHtml != null) {
      printHtmlInvoice(model.cargoHtml)
    }
    if (model.orderHtml != null) { 
      printHtmlInvoice(model.orderHtml)
    }
    if(model.invoicePDF != null) {
      printJS({printable: model.invoicePDF, type: 'pdf', base64: true})
    } 
    resolve();
  });
}

function saveLastPrint(item) {
  localStorage.setItem('wmsPrint', JSON.stringify(item))
}
export async function printLastPrintedItem(printedObj) {
  if (printedObj.type == 'html') {
    printHtmlInvoice(printedObj.model)
  } else if(printedObj.type == 'pdf') {
    printJS({printable: printedObj.model, type: 'pdf', base64: true})
  }
}



//////CUSTOM PRINT
export async function checkLocalService(warning=false) {
  return await axiosInstance.get('http://localhost:5454/api/health-check')
  .then((res)=> {
    if(warning) Message({type: 'success', message: `Belgeleriniz yazıcıya gönderiliyor.`})
    return res
  })
  .catch((err)=> {
    Message({type: 'error', message: 'Yazıcı Servisine Bağlanılamıyor. Lütfen Ticimax Destek ile iletişime geçiniz.'})
    throw new Error(err)
  })
}

export async function getPrinterList() {
  await checkLocalService()
  return await axiosInstance.get('http://localhost:5454/api/printers')
  .then((res)=> {
    return res
  })
  .catch((err)=> {
    throw new Error(err)
  })
}


async function localPrintHtmlHandler(settings,content,printerName, type) {
  await axiosInstance.post('http://localhost:5454/api/printers/print-content', {
    "content": content,
    "printerSettings": {
      "printerName": printerName,
      ...settings
    }
  })
  .then((res)=> {
    saveLastPrint({
      type: 'html',
      model: content
    })
    Message({type: 'success', message: `${type} çıktınız ${printerName} isimli yazıcıya gönderilmiştir.`})
  })
  .catch((err)=> {
    console.log(err)
  })
}
async function localPrintPdfHandler(settings,content,printerName, type) {
  await axiosInstance.post('http://localhost:5454/api/printers/print-file', {
    "file": content,
    "printerSettings": {
      "printerName": printerName,
      ...settings
    }
  })
  .then((res)=> {
    saveLastPrint({
      type: 'pdf',
      model: content
    })
    Message({type: 'success', message: `${type} çıktınız ${printerName} isimli yazıcıya gönderilmiştir.`})
  })
  .catch((err)=> {
    console.log(err)
  })
}

