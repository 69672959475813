import Vue from 'vue'
import { MessageBox } from 'element-ui';
import i18n from '@/locales' 
import router from "@/router"

const state = () => ({
  order: {},
  products: []
})

const getters = {
  
}

const mutations = {
  setOrder: (state, payload) => {
    payload.totalBankComission = 0
    payload.totalDoorAmount = 0
    payload.payments.map(item=> {
      payload.totalBankComission+=item.bankCommission
      payload.totalDoorAmount+=item.payDoorAmount
    })
    state.order = payload
  },
  setProducts: (state, payload) => {
    state.products = payload
  }
}

const actions = {
  async getOrderDetail({ commit }, {id, no}) {
    try {
      const response = await Vue.prototype.$axios.get(id ? `/api/Order/${id}` : `/api/Order/no/${no}`)
      commit('setOrder', await response.data)
      commit('setProducts', await response.data.products)
    } catch (error) {
      if (error.response.data.Key == 'ORDER_IS_NOT_FOUND') {
        MessageBox.alert('', i18n.t(error.response.data.Key), {
          center: true,
          showClose: false,
          type: 'warning',
          confirmButtonText: i18n.t('SiparisListesineDon'),
          callback: action => {
            router.push({name: 'TumSiparisler'})
          }
        })
      }
      console.log("%cBir Hata Oluştu. Kod:"+error.response.status,"color: white; background:red; border-radius:6px; padding:3px; font-size: 13px");
    }
  },
  async resetOrder({state}) {
    try {
      const response = await Vue.prototype.$axios.post('/api/order/orderReset', {
        orderId: state.order.id
      })
      return true
    } catch (error) {
      throw error
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}