import axios from "axios";
import Vue from "vue"

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
});
axiosInstance.interceptors.request.use(config => {

  if(Vue.$cookies.get('customApiURL')) config.baseURL = Vue.$cookies.get('customApiURL')
  config.headers["usertoken"] = Vue.$cookies.get('userToken') ?? null
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    
    return response
  },(error) => {
   
    return Promise.reject(error)
  }
);