import Vue from 'vue'
import { Message, MessageBox  } from 'element-ui';
import i18n from '@/locales' 
import router from "@/router"

const state = () => ({
  barcodeType: 0, // 0=sipariş, 1=ürün
  refundInformation: {
    order: {
      products: []
    }
  },
  readedProductList: [], //iade için okutulan ürünler
  returnInformation: { //iade isteği için atılıcak değerler
    returnPaymentType: '-1',
    cargoReturn: false,
    ReflectFixedShippingAmount:false,
    payDoorReturn: false,
    ReduceShippingCostAmount:0,
    iban: '',
    ibanName: ''
  },
  returnReason: null, //ürünlerin içine atılıcak iade sebebi
})
  
// getters
const getters = {
  activeOrder(state) {
    return state.refundInformation.order
  },
  receivedStatus(state) {
    if (state.refundInformation.order.status < 7 ||
      state.refundInformation.order.status == 18) {
      return false //teslim alınmamış
    } else {
      return true      
    }
  },
  productList(state) {
    return state.refundInformation.order.products.filter(item=> item.returnPiece != item.piece)
  },
  refundProductList(state) {
    return state.refundInformation.order.products.filter(item=> item.returnPiece > 0)
  },
  returnCauses(state) {
    return state.refundInformation.returnCausies
  },
  returnTypes(state) {
    return state.refundInformation.returnType
  },
  easyReturn(state) {
    return state.refundInformation.returnOrderRequest
  }
}
  
// actions
const actions = {
  
  async getOrder({commit}) {
    try {
      const response = await Vue.prototype.$axios.get('/api/return-orders/barcodes/'+this.state.app.barcode)
      commit('setRefundInformation', response.data.model)
      commit('changeBarcodeType', 1)
      if (response.data.model.isCampaignOrder) {
        MessageBox.alert(i18n.t('SiparisteKampanyaUygulanmistir'), i18n.t('Dikkat'), {
          center: true,
          type: 'warning',
          confirmButtonText: i18n.t('Tamam')
        })
      }
      if (response.data.model.order.giftVoucherAmount > 0) {
        MessageBox.alert(
          i18n.t("SiparisteHediyeCekiUygulanmistir"),
          i18n.t("Dikkat"),
          {
            center: true,
            type: "warning",
            confirmButtonText: i18n.t("Tamam"),
          }
        );
      }
    } catch (error) {
      if (error.response.data.Key == 'ORDER_IS_NOT_FOUND') {
        Message({type:'warning', message: i18n.t('ORDER_IS_NOT_FOUND')})
      }
      // console.log(error)
    }
  },
  async completeRefund({getters, state, commit}) {
    if (this.state.user.settings.iadeAyar.iadeNedeniAktif && state.returnReason) {
      state.refundInformation.order.products.map(item=> item.returnReasonID = state.returnReason)
    }
    try {
      state.returnInformation.returnPaymentType = parseInt(state.returnInformation.returnPaymentType)
      const response = await Vue.prototype.$axios.put(`/api/return-orders/${getters.activeOrder.id}/completed`, {
        ...state.returnInformation,
        products: getters.refundProductList,
        deliveryBeforeReturn: !getters.receivedStatus,
      })
      Message({type:'success', message: i18n.t('BasariylaIadeEdildi')})
      commit('resetRefund')
    } catch (error) {
      console.log(error)
    }
  }
  
 
}
  
// mutations
const mutations = {
  changeBarcodeType(state, payload) {
    state.barcodeType = payload
  },
  setRefundInformation(state, payload) {
    state.refundInformation = payload
    if (payload.returnOrderRequest) {
      state.returnInformation.iban = payload.returnOrderRequest.iban
      state.returnInformation.ibanName = payload.returnOrderRequest.ibanName
    }
    payload.order.refundDoorPayment = 0
    payload.order.refundDoorPayment+= parseFloat(payload.order.payments.map(item=> item.payDoorAmount))
  },
  resetRefund(state) {
    state.refundInformation = {}
    state.readedProductList = []
    state.returnInformation =  { 
      returnPaymentType: '-1',
      cargoReturn: false,
      ReflectFixedShippingAmount:false,
      ReduceShippingCostAmount:0,
      payDoorReturn: false,
      iban: null,
      ibanName: null
    }
    this.commit('refund/changeBarcodeType', 0)
  },
  readProduct(state) {
    //önce ürün listesinde var mı bak
    let findProductIndexInProductList = state.refundInformation.order.products.findIndex(item=> item.barcode == this.state.app.barcode)
    let findedProduct = state.refundInformation.order.products[findProductIndexInProductList]
    if (findProductIndexInProductList >= 0) {
      if (findedProduct.piece == findedProduct.returnPiece) { //iade edilenle maksimum ürün sayısı eşitse
        return Message({type:'warning', message: i18n.t('UrunBulunamadi')})
      }
      findedProduct.returnPiece+=1
    } else {
      return Message({type:'warning', message: i18n.t('UrunBulunamadi')})
    }
  },
  refundAllProducts(state) {
    state.refundInformation.order.products.map(item=> item.returnPiece = item.piece)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}