import Vue from 'vue'

const state = () => ({
  step: 1,
  isFormValidate: false,
  adminId: null,
  packageStatus: [],
  warehousePackageStatus: [],
  warehousePackageSelectedStatus: []
})
  
// getters
const getters = {
  
}
  
// actions
const actions = {
  async createSetupProcess({commit}) {
    try {
      const response = await Vue.prototype.$axios.put('/api/setup-wizard/started')
      commit('changeStep', 1)
      // localStorage.setItem('setupStep', 1)
    } catch (error) {
      //HATA
    }
  },
  async completeSetupProcess({commit}) {
    try {
      const response = await Vue.prototype.$axios.put('/api/setup-wizard/completed')
      this.dispatch('user/clearAllUserInformation')
      // localStorage.removeItem('setupStep')
      commit('nextStep')
    } catch (error) {
      //HATA
    }
  },
  // YENİ KULLANICI İSTEKLERİ
  async createAdminUser({commit, state},userInformation) {
    try {
      const response = await Vue.prototype.$axios.post('/api/StoreAgent/Add', userInformation)
      if (response.data.isError) return
      state.adminId = response.data.model.id
      Vue.$cookies.set('personID', response.data.model.id)
      commit('nextStep')
      this.commit('user/setStoreId', null)
    } catch (error) {
      //HATA
    }
  },
  //DEPO SEÇİM İSTEKLERİ
  async setStore({dispatch, state}) {
    try {
      const response = await Vue.prototype.$axios.get('/api/StoreAgent/UpdateStoreID', {params: {
        id: state.adminId,
        storeID: JSON.parse(Vue.$cookies.get('storeID'))
      }})
    } catch (error) {
      //HATA
    }
  },
  async setWarehouse({commit, state}) {
    try {
      const response = await Vue.prototype.$axios.get('/api/StoreAgent/UpdateWarehouseID', {params: {
        id: state.adminId,
        warehouseID: JSON.parse(Vue.$cookies.get('warehouseID'))
      }})
      commit('nextStep')
    } catch (error) {
      //HATA
    }
  },
  //PAKETLEME DURUMU İSTEKLERİ
  async getDefaultPackageStatus({state}) {
    try {
      const response = await Vue.prototype.$axios.get('/api/package-statuses')
      state.packageStatus = response.data
    } catch (error) {
      console.log("%cBeklenmeyen Bir Hata Oluştu.","color: white; background:red; border-radius:6px; padding:3px; font-size: 13px");
    }
  },
  async getDefaultStatus({state}) {
    try {
      const response = await Vue.prototype.$axios.get('/api/package-statuses/processes')
      state.warehousePackageStatus = response.data
      // state.warehousePackageSelectedStatus = response.data
      // state.warehousePackageSelectedStatus.map(item => item.id = null)
    } catch (error) {
      console.log("%cBeklenmeyen Bir Hata Oluştu.","color: white; background:red; border-radius:6px; padding:3px; font-size: 13px");
    }
  },

}
  
// mutations
const mutations = {
  changeStep(state, payload) {
    state.step = payload
    // localStorage.setItem('setupStep', payload)
  },
  nextStep(state) {
    state.step++
    // localStorage.setItem('setupStep', state.step)
  },
  setFormValidation(state, payload) {
    state.isFormValidate = payload
  },
  setAdminId(state) {
    state.adminId = Vue.$cookies.get('personID')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}