const base = [
  {
    path: '/order-management/picking-car',
    name: 'ToplamaArabasi',
    component: () => import(/* webpackChunkName: "ToplamaArabasi" */ '@/views/OperationManagement/PickingCar.vue'),
    meta: {
      title: 'ToplamaArabasi',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.PickingCar.ShowPage'
    },
  },
  {
    path: '/order-management/picking/',
    name: 'SiparisToplama',
    component: () => import(/* webpackChunkName: "SiparisToplama" */ '@/views/OperationManagement/Picking.vue'),
    meta: {
      title: 'SiparisToplama',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.ProductPicking.ShowPage'
    },
  },
  {
    path: '/order-management/order-selecting/',
    name: 'SiparisSecim',
    component: () => import(/* webpackChunkName: "SiparisSecim" */ '@/views/OperationManagement/OrderSelect.vue'),
    meta: {
      title: 'SiparisSecim',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.OrderSelect.ShowPage'
    },
  },
  {
    path: '/order-management/order-selecting/product',
    name: 'SiparisSecimUrun',
    component: () => import(/* webpackChunkName: "SiparisSecimUrun" */ '@/views/OperationManagement/OrderSelectProduct.vue'),
    meta: {
      title: 'SiparisSecim',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.OrderSelect.ShowPage'
    },
  },
  {
    path: '/order-management/order-selecting/location',
    name: 'SiparisSecimPeron',
    component: () => import(/* webpackChunkName: "SiparisSecimPeron" */ '@/views/OperationManagement/OrderSelectLocation.vue'),
    meta: {
      title: 'SiparisSecim',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.OrderSelect.ShowPage'
    },
  },
  {
    path: '/order-management/order-selecting/main-location',
    name: 'SiparisSecimPeron',
    component: () => import(/* webpackChunkName: "SiparisSecimPeron" */ '@/views/OperationManagement/OrderSelectMainLocation.vue'),
    meta: {
      title: 'SiparisSecim',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.OrderSelect.ShowPage'
    },
  },
  {
    path: '/order-management/order-selecting/main-location-single-product',
    name: 'SiparisSecimPeron',
    component: () => import(/* webpackChunkName: "SiparisSecimPeron" */ '@/views/OperationManagement/OrderSelectMainLocationSingleProduct.vue'),
    meta: {
      title: 'SiparisSecim',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.OrderSelect.ShowPage'
    },
  },
  {
    path: '/order-management/order-selecting/main-location-multi-product',
    name: 'SiparisSecimPeron',
    component: () => import(/* webpackChunkName: "SiparisSecimPeron" */ '@/views/OperationManagement/OrderSelectMainLocationMultiProduct.vue'),
    meta: {
      title: 'SiparisSecim',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.OrderSelect.ShowPage'
    },
  },
  {
    path: '/order-management/order-selecting/countries',
    name: 'UlkeSecimPeron',
    component: () => import(/* webpackChunkName: "UlkeSecimPeron" */ '@/views/OperationManagement/OrderSelectCountries.vue'),
    meta: {
      title: 'SiparisSecim',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.OrderSelect.ShowPage'
    },
  },
  {
    path: '/order-management/order-assembly',
    name: 'SiparisBirlestirme',
    component: () => import(/* webpackChunkName: "SiparisBirlestirme" */ '@/views/OperationManagement/OrderAssembly.vue'),
    meta: {
      title: 'SiparisBirlestirme',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.OrderCombine.ShowPage'
    },
  },
  {
    path: '/order-management/quality-control',
    name: 'KaliteKontrol',
    component: () => import(/* webpackChunkName: "KaliteKontrol" */ '@/views/OperationManagement/QualityControl.vue'),
    meta: {
      title: 'KaliteKontrol',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.QualityControl.ShowPage'
    },
  },
  {
    path: '/order-management/final-control',
    name: 'KargoKontrol',
    component: () => import(/* webpackChunkName: "KargoKontrol" */ '@/views/OperationManagement/FinalCargoControl.vue'),
    meta: {
      title: 'KargoKontrol',
      layout: 'AppLayoutNoBg',
    },
  },
  {
    path: '/order-management/missing-product-list',
    name: 'EksikUrunListesi',
    component: () => import(/* webpackChunkName: "EksikUrunListesi" */ '@/views/OperationManagement/MissingProductList.vue'),
    meta: {
      title: 'EksikUrunListesi',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.MissingProductList.ShowPage'
    },
  },
  {
    path: '/order-management/missing-product-management',
    name: 'EksikUrunYonetimi',
    component: () => import(/* webpackChunkName: "EksikUrunYonetimi" */ '@/views/OperationManagement/MissingProductManagement.vue'),
    meta: {
      title: 'EksikUrunYonetimi',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.MissingProductList.ShowPage'
    },
  },
  {
    path: '/order-management/missing-product-combine',
    name: 'EksikUrunBirlestirme',
    component: () => import(/* webpackChunkName: "EksikUrunBirlestirme" */ '@/views/OperationManagement/MissingProductCombine.vue'),
    meta: {
      title: 'EksikUrunBirlestirme',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.MissingProductCombine.ShowPage'
    },
  },
  {
    path: '/order-management/set-manager/',
    name: 'SetYonetimi',
    component: () => import(/* webpackChunkName: "SetYonetimi" */ '@/views/OrderManagement/SetManager.vue'),
    meta: {
      title: 'SetYonetimi',
      layout: 'AppLayoutDefault',
      // auth: 'OrderManagement.SetManager.ShowPage'
    },
  },
  {
    path: '/order-management/orders/',
    name: 'TumSiparisler',
    component: () => import(/* webpackChunkName: "TumSiparisler" */ '@/views/OrderManagement/Orders.vue'),
    meta: {
      title: 'TumSiparisler',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.AllOrders.ShowPage'
    },
  },
  {
    path: '/order-management/order-detail',
    name: 'SiparisDetay',
    component: () => import(/* webpackChunkName: "SiparisDetay" */ '@/views/OrderManagement/OrderDetail.vue'),
    meta: {
      title: 'SiparisDetay',
      layout: 'AppLayoutNoBg',
      auth:'OrderManagement.OrderDetails.ShowPage'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.id || to.query.orderNo) {
        next()
      } else {
        next({name: 'TumSiparisler'})
      }
    }
  },
  {
    path: '/order-management/waiting-orders/',
    name: 'BekleyenSiparisler',
    component: () => import(/* webpackChunkName: "BekleyenSiparisler" */ '@/views/OrderManagement/WaitingOrders.vue'),
    meta: {
      title: 'BeklemeModundakiSiparisler',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.AllOrders.ShowPage'
    },
  },
  {
    path: '/order-management/completed-orders/',
    name: 'TamamlananSiparisler',
    component: () => import(/* webpackChunkName: "TamamlananSiparisler" */ '@/views/OrderManagement/CompletedOrders.vue'),
    meta: {
      title: 'TamamlananSiparisler',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.CompletedOrders.ShowPage'
    },
  },
  {
    path: '/order-management/ongoing-orders/',
    name: 'DevamEdenSiparisler',
    component: () => import(/* webpackChunkName: "DevamEdenSiparisler" */ '@/views/OrderManagement/OngoingOrders.vue'),
    meta: {
      title: 'DevamEdenSiparisler',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.OngoingOrders.ShowPage'
    },
  },
  {
    path: '/order-management/missing-orders/',
    name: 'EksikUrunluSiparisler',
    component: () => import(/* webpackChunkName: "EksikUrunluSiparisler" */ '@/views/OrderManagement/MissingOrders.vue'),
    meta: {
      title: 'EksikUrunluSiparisler',
      layout: 'AppLayoutDefault',
      auth:'OrderManagement.MissingOrders.ShowPage'
    },
  },
  {
    path: '/order-management/print-cargo/',
    name: 'KargoCiktisiAl',
    component: () => import(/* webpackChunkName: "KargoCiktisiAl" */ '@/views/OrderManagement/PrintCargo.vue'),
    meta: {
      title: 'KargoCiktisiAl',
      layout: 'AppLayoutDefault',
      auth: 'OrderManagement.OrderDetails.PrintBill'
    },
  },
  // {
  //   path: '/order-management/deliver-to-courier',
  //   name: 'KuryeyeVer',
  //   component: () => import(/* webpackChunkName: "KuryeyeVer" */ '@/views/OrderManagement/DeliverToCourier.vue'),
  //   meta: {
  //     title: 'KuryeyeVer',
  //     layout: 'AppLayoutDefault'
  //   },
  // }
];

export default base;
