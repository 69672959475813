import Vue from 'vue'

// import router from '@/router'
// import { Message } from 'element-ui';
// import i18n from '@/locales' 
import { encrypt, decrypt } from '@/helpers/utils/encrypt'


const state = () => ({
  user: {},
  settings: {},
  permissions: {},
  setInfo: {}
})
  
// getters
const getters = {
  getUser(state) {
    return state.user
  },
  getSettings(state) {
    return state.settings
  },
  getSettingCarModule(state) {
    return state.settings.arabaModulAktif
  },
  isUserCallCustomer(state) {
    return state.settings.telephoneCentralSettings.active
  },
  getPermissions(state) {
    return state.permissions
  },
  isOMSActive(state) {
    return state.settings.packetSettings.omniChannelDistributionActive
  },
  getUserWarehoseStoreInformation(state) {
    return {
      storeID: state.user.storeID,
      storeName: state.user.storeName,
      warehouseID: state.user.warehouseID,
      warehouse: state.user.warehouse,
    }
  },
  getSegmentedBacodeSettings(state) {
    return state.settings.barcodeSettings.segmentedBarcodeSettings
  }
}
  
// actions
const actions = {
  async setUserAction({commit}) {  
    if (Vue.$cookies.get('userToken') == null && !window.location.toString().includes("login") && !window.location.pathname.includes('/forgot')) {
      router.push('/login')
      return
      
    }
    if(!Vue.$cookies.get('tcmxwmsuser')) return
    commit('setUser', {
      ...JSON.parse(decrypt(Vue.$cookies.get('tcmxwmsuser'))),
    })
    commit('setSettings', JSON.parse(decrypt(localStorage.getItem('settings'))))
    commit('setPermisions', JSON.parse(decrypt(localStorage.getItem('permissions'))))
  },
  async getUserInformation({dispatch}) {
    try {
      const response = await Vue.prototype.$axios.get(`/api/StoreAgent/GetAllInformationsOfUser`)

      await dispatch('setUserInformation', response.data.model)
      await dispatch('setUserAction')

    } catch (error) {
      console.log("%cBir Hata Oluştu.","color: white; background:red; border-radius:6px; padding:3px; font-size: 13px");
    }
  },
  async setUserInformation({}, data) {

    Object.assign(data.settings, {paymentSetting: data.paymentSetting});
    localStorage.setItem('settings', encrypt(JSON.stringify(data.settings)).toString())
    localStorage.setItem('permissions', encrypt(JSON.stringify(data.authority)).toString())
    Vue.$cookies.set('userType', data.type)

    // Vue.$cookies.set('personID', data.id)
    // Vue.$cookies.set('name', data.name)
    // Vue.$cookies.set('authGroupName', data.authGroupName)
    // Vue.$cookies.set('warehouseName', data.warehouse)
    // Vue.$cookies.set('storeName', data.storeName)
    // Vue.$cookies.set('domainName', data.domainName.toLocaleLowerCase('tr-TR'))
    // Vue.$cookies.set('apiVersion', data.version);


    if (data.storeID !== 0)
      Vue.$cookies.set('storeID', data.storeID);
    if (data.warehouseID !== 0)
      Vue.$cookies.set('warehouseID', data.warehouseID);
    localStorage.setItem('fastRedirectMenu', JSON.stringify(data.quickMenus));
    
    let cleanResponse = {...data}
    //boyut küçültmek için siliyoruz bunları
    delete cleanResponse.quickMenus
    delete cleanResponse.paymentSetting
    delete cleanResponse.settings 
    delete cleanResponse.authority
    
    Vue.$cookies.set('tcmxwmsuser', encrypt(JSON.stringify(cleanResponse)).toString())
  },
  clearAllUserInformation({state}) {
    state.user = {}
    state.settings = {},
    state.permissions = {}
    Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
  },
  setStoreIdAction({commit}, payload) {
    let newSetting = JSON.parse(decrypt(Vue.$cookies.get('tcmxwmsuser')))
    newSetting.storeID = payload
    Vue.$cookies.set('tcmxwmsuser', encrypt(JSON.stringify(newSetting)).toString())
    Vue.$cookies.set('storeID', payload)
    commit('setStoreId', payload)
  },
  setWarehouseIdAction({commit}, payload) {
    let newSetting = JSON.parse(decrypt(Vue.$cookies.get('tcmxwmsuser')))
    newSetting.warehouseID = payload
    Vue.$cookies.set('tcmxwmsuser', encrypt(JSON.stringify(newSetting).toString()))
    Vue.$cookies.set('warehouseID', payload)
    commit('setWarehouseId', payload)
  },
  async getUserSetInformation({commit, state}) {
    // if(state.setInfo) return
    try {
      const response = await Vue.prototype.$axios.get(`/api/OrderCollection/user-set`)
      commit('handleSetInformation', response.data.model)
    } catch (error) {
      if (error.response.data.Key == 'USER_SET_NOT_FOUND') {
        commit('handleSetInformation', error.response.data.Key)
      }
      // throw error
    }
  }
}
  
// mutations
const mutations = {
  setUser(state, payload) {
    state.user = payload
  },
  setSettings(state, payload) {
    state.settings = payload
  },
  setPermisions(state, payload) {
    state.permissions = payload
  },
  setStoreId(state, payload) {
    state.user.storeID = payload
  },
  setWarehouseId(state, payload) {
    state.user.warehouseID = payload
  },
  handleSetInformation(state, payload) {
    state.setInfo = payload
  },
  pickProductForSetInformation(state) {
    state.setInfo.occurrencesPiece++
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}