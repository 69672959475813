import Vue from 'vue'


const base = [
  {
    path: '/courier/orders',
    name: 'DagitimaHazirSiparisler',
    component: () => import(/* webpackChunkName: "DagitimaHazirSiparisler" */ '@/views/Courier/Orders.vue'),
    meta: {
      title: 'DagitimaHazirSiparisler',
      layout: 'AppLayoutDefault',
      auth: 'Carrier.ShowPage'
    },
    beforeEnter: (to, from, next) => {
      if (Vue.$cookies.get('userType') == 2) {
        next()
      } else {
        next({name: 'Anasayfa'})
      }
    }
  },
  {
    path: '/courier/order-detail',
    name: 'DagitimaHazirSiparisDetay',
    component: () => import(/* webpackChunkName: "DagitimaHazirSiparisDetay" */ '@/views/Courier/OrderDetail.vue'),
    meta: {
      title: 'SiparisTeslim',
      layout: 'AppLayoutNoBg',
      auth: 'Carrier.Delivered'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.id && Vue.$cookies.get('userType') == 2) {
        next()
      } else {
        next({name: 'Anasayfa'})
      }
    }
  },
]

export default base;