<template>
  <div class="chart-wrapper">
    <div class="chart-header">
      <div class="row">
        <h3>{{ $t('SiparisSayisi') }} <span>({{ $t('Son24Saat') }})</span></h3>
      </div>
    </div>
    <div class="chart-inner">
      <Transition name="fade" mode="out-in">
        <LineChartGenerator v-if="visible" :options="chartOptions" :data="chartData" />
        <el-skeleton :rows="8" animated v-else />
      </Transition>
    </div>
    <Transition name="fade" mode="out-in">
      <div class="chart-footer" v-if="visible">
        <div class="chart-information">
          <div class="chart-info--item">
            <div class="icon"><shopping-cart></shopping-cart></div>
            <div class="content">
              <h4>{{ $t('TekliSiparis') }}</h4>
              <h5>{{ last24hOrders.oneProductOrderCount }}
                <span>{{ last24hOrders.oneProductOrderCount }} {{ $t('Urun') }}</span>
              </h5>
            </div>
          </div>
          <div class="chart-info--item">
            <div class="icon"><shopping-cart-2></shopping-cart-2></div>
            <div class="content">
              <h4>{{ $t('CokluSiparis') }}</h4>
              <h5>{{ last24hOrders.aLotsOfProductOrderCount }}
                <span>{{ last24hOrders.aLotsOfProductOrderProductCount }} {{ $t('Urun') }}</span>
              </h5>
            </div>
          </div>
        </div>
        <div class="total-chart-info">
          <h4>{{ $t('ToplamSiparis') }}</h4>
          <h5>{{ last24hOrders.orderCount.toLocaleString() }}</h5>
        </div>
      </div>

    </Transition>
  </div>
</template>

<script>
import ShoppingCart from '@/components/Icons/IconShoppingCart.vue'
import ShoppingCart2 from '@/components/Icons/IconShoppingCart2.vue'

import { Line as LineChartGenerator } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler)

export default {
  components: { LineChartGenerator, ShoppingCart, ShoppingCart2 },
  computed: {
    last24hOrders() {
      return this.$store.state.dashboard.last24hOrders
    },
    visible() {
      return this.$store.state.dashboard.last24hOrdersVisibilty
    }
  },
  data() {
    return {
      chartData: {
        labels: ['00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',],
        datasets: [
          {
            label: this.$t('TekliSiparis'),
            data: this.$store.state.dashboard.last24hOrdersChart[0],
            fill: true,
            backgroundColor: 'rgb(142 235 191 / 45%)',
            pointBackgroundColor: 'rgb(142 235 191 / 100%)',
            borderColor: 'rgb(142 235 191 / 100%)',
            borderWidth: 2,
            tension: .7,
            pointBorderWidth: 2,
            pointRadius: 2,
          },
          {
            label: this.$t('CokluSiparis'),
            data: this.$store.state.dashboard.last24hOrdersChart[1],
            fill: true,
            backgroundColor: 'rgb(0 84 123 / 47%)',
            pointBackgroundColor: 'rgb(0 84 123 / 100%)',
            borderColor: 'rgb(0 84 123 / 100%)',
            borderWidth: 2,
            tension: .7,
            pointBorderWidth: 2,
            pointRadius: 2,
          },
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          y: {
            min: 0,
          }
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  flex: 3 0 45%;
  max-width: calc(50% - 5px);

  @include screen-down(lg) {
    max-width: 100%;
    flex: 1 0 100%;
    width: 100%;
  }
}
</style>