import Vue from 'vue'
import { Message, MessageBox } from 'element-ui';
import i18n from '@/locales'
import router from "@/router"

const state = () => ({
  barcodeType: 0, //0=raf okut 1=ürün okut 3=sipariş okut
  setNo: '',
  productsStats: {
    totalProductCount: 0,
    collectedProductCount: 0,
    missingProductCountInSet: 0,
    remainingProductCount: 0
  },
  orderList: [], //sipariş listesi
  activeOrder: null,
  activeOrderId: null,

  isAllAcceptProduct: false,
  products: [], //ürün listesi
  pickedProducts: [], //toplanan ürün listesi
  missingProduct: {}, //eksik ürünle işlem yapmak için
  isMissingProduct: false,
  activeProduct: {}, //seçilen herhangi bir ürünle işlem yapmak için

  shelfList: [], //raf listesi
  activeShelfId: null, //seçili raf
  returnCauses: [], //eksik ürün nedenleri
  allProductFound: false,

  otherShelfProductList: [],
  accordionShowReadProducts: [],

  popups: {
    missingPopup: false, //eskik ürün popup
    quantityPopup: false, //adet düzenleme popup
    desiPopup: false, //desili ürün adet popup
    otherShelfsPopup: false,
    changeProductPopup: false
  }
})

// getters
const getters = {
  findOrder: (state) => (orderNo) => {
    return state.orderList.find(order => order.orderNo == orderNo)
  },
  getAllProducts(state) {
    return state.products
  }
}

// actions
const actions = {
  async pageOpening({ dispatch, commit, state }, geTstats = true) {
    let pickingType = this.getters['user/getSettings'].urunToplamaAyar.urunToplamaYontemi
    try {
      if (geTstats) {
        const response = await Vue.prototype.$axios.get('/api/picking-products/informations')
        commit('setproductsStats',
          {
            totalProductCount: response.data.totalProductCount,
            collectedProductCount: response.data.collectedProductCount,
            remainingProductCount: response.data.remainingProductCount,
            missingProductCountInSet: response.data.missingProductCountInSet
          })
        state.setNo = response.data.setNo
      }
      await dispatch('getReturnCauses')
      if (pickingType == 1 || pickingType == 2) {
        await dispatch('getShelfList')
      } else if (pickingType == 3) {
        commit('changeBarcodeType', 1)
        await dispatch('getProducts')
      } else if (pickingType == 5) {
        await dispatch('getOrderList')
      }
    } catch (error) {
      if (error.response.data.Key == "SiparisinizYok") {
        MessageBox.alert(i18n.t('UzerinizdeSiparisYok'), i18n.t('SiparisinizYok'), {
          center: true,
          showClose: false,
          type: 'warning',
          confirmButtonText: i18n.t('SiparisSecimeGit'),
          callback: action => {
            router.push({ name: 'SiparisSecim' })
          }
        })
      } else if (error.response.data.Key == "ArabaBulunamadi") {
        MessageBox.alert(i18n.t('UzerinizdeArabaYok'), i18n.t('ArabaBulunamadi'), {
          center: true,
          showClose: false,
          type: 'warning',
          confirmButtonText: i18n.t('ArabaSecimeGit'),
          callback: action => {
            router.push({ name: 'ToplamaArabasi' })
          }
        })
      }
    }
  },
  async getShelfList({ commit, state }) {
    try {
      const response = await Vue.prototype.$axios.get('/api/OrderCollection/shelfs')
      state.shelfList = response.data.contents
      commit('setActiveShelf', {}) //aktif rafı sıfırlama
      commit('changeBarcodeType', 0) // raf okumaya dönüş
      if (response.data.contents.length == 0) {
        commit('completePickingAlert')
      }
    } catch (error) {
      //HATA
    }
  },
  async getOrderList({ dispatch, commit }) {
    try {
      const response = await Vue.prototype.$axios.get('/api/OrderCollection/orders')
      commit('updateOrderList', response.data.contents)
      commit('changeBarcodeType', 3)
      commit('setActiveOrderId', null)
      // if (response.data.contents.length == 1) { //tek sipariş varsa onu aktif et
      //   commit('setActiveOrderId', response.data.contents[0].orderId)
      //   commit('changeBarcodeType', 1)
      //   await dispatch('getOrderDetail')
      //   await dispatch('getProducts')
      // }
      if (response.data.contents.length == 0) {
        commit('completePickingAlert')
      }
    } catch (error) {
      //HATA
    }
  },
  async getProducts({ commit, state }) {
    try {
      const response = await Vue.prototype.$axios.get('/api/picking-products', {
        params: {
          orderId: state.activeOrderId,
          shelfId: state.activeShelfId
        }
      })
      commit('setProducts', response.data.model.products)
      // let totalCount = 0;
      // response.data.model.products.forEach(element => {
      //   totalCount += element.piece
      // });
      // state.productsStats.remainingProductCount = totalCount
      // state.productsStats.totalProductCount = totalCount

      const lastCounts = await Vue.prototype.$axios.get('/api/picking-products/informations')
      commit('setproductsStats',
        {
          totalProductCount: lastCounts.data.totalProductCount,
          collectedProductCount: lastCounts.data.collectedProductCount,
          remainingProductCount: lastCounts.data.remainingProductCount,
          missingProductCountInSet: lastCounts.data.missingProductCountInSet
        })
      state.setNo = lastCounts.data.setNo
      
      if (response.data.model.products.length == 0) {
        commit('completePickingAlert')
      }
    } catch (error) {
      //HATA
    }
  },
  async getOrderDetail({ commit, state }) {
    // console.log(this.getters['app/getBarcode'])
    try {
      const response = await Vue.prototype.$axios.get('/api/Order/' + state.activeOrderId)
      commit('setActiveOrder', response.data)
    } catch (error) {
      //HATA
    }
  },
  //eksik ürün sebepleri
  async getReturnCauses({ commit, state }) {
    if (state.returnCauses.length != 0) return
    try {
      const response = await Vue.prototype.$axios.get('/api/OrderProductReturnCause/GetList', {
        params: {
          active: 1
        }
      }, { settings: { disableLoading: true } })
      response.data.model.push({
        "id": 0,
        "definition": "Seçiniz",
        "active": true
      })
      commit('setReturnCauses', response.data.model)
    } catch (error) {
      console.warn(error)
    }
  },
  //diğer raflardaki ürünleri gör
  async getOtherShelfData({ commit, state }) {
    try {
      const response = await Vue.prototype.$axios.get('/api/ShelfProduct/GetList', {
        params: {
          productID: state.activeProduct.productID
        }
      })
      if (response.data.isError) return
      if (response.data.model.length == 0) {
        Message({ type: 'warning', message: i18n.t('UrunBulunamadi') })
        return
      }
      commit('setOtherShelfList', response.data.model)
      commit('setOtherShelfsPopup', true)
      // this.otherShelfsPopupTitle = product.barcode+' '+this.$t('BarkodluUrununRaflardakiSayisi')

    } catch (error) {
      //HATA
    }
  },
  //ürün adeti güncelle
  async updateProductQuantity({ dispatch, commit, state }) {
    try {
      const response = await Vue.prototype.$axios.put('/api/picking-products/occurencies-piece',
        {
          orderProductID: state.activeProduct.orderProductID,
          piece: state.activeProduct.piece
        })
      if (response.data.isError) return
      commit('setEditQuantityPopup', false)
      Message({ type: 'success', message: i18n.t('IslemBasarili') })
    } catch (error) {

    }
  },
  //ürün barkodu ile ürün ara
  searchProduct({ state }, payload) {
    if (this.getters['user/getSettings'].urunToplamaAyar.cokluBarkod) {
      return state.products.find(
        (item) => item.barcode == payload || item.barcodes.includes(payload)
      );
    } else if (this.getters['user/getSettings'].urunToplamaAyar.fazlaOkutma) {
      return state.products.find((item) => item.barcode == payload)
    } else {
      return state.products.find((item) => item.barcode == payload && item.piece != item.occurrencesPiece)
    }
  },
  //ÜRÜNÜ BULDUM/TOPLADIM
  async controlProduct({ dispatch, commit, state, deneme }) {
    if (state.activeProduct.isProductPieceDecimal) {
      commit('setProductDesiPopup', true)
      return
    }
    await dispatch('pickProduct')
  },
  async pickProduct({ dispatch, commit, state }, piece = 1) {
    if (piece == 0) {
      Message({ type: 'error', message: i18n.t('PICKING_PRODUCT_PIECE_NOT_EQUALS_ZERO') })
      return
    }
    try {
      const response = await Vue.prototype.$axios.post('/api/picking-products/pick', {
        orderID: state.activeProduct.orderID,
        productBarcode: state.activeProduct.productBarcode,
        productID: state.activeProduct.productID,
        orderProductID: state.activeProduct.orderProductID,
        shelfID: state.activeProduct.shelfID,
        piece: state.isAllAcceptProduct ? (state.activeProduct.piece - (state.activeProduct.missingPiece + state.activeProduct.occurrencesPiece)) : piece,
        setNo: state.setNo,
        isMissingProduct: false,
      })
      commit('updateTotalCounts', state.isAllAcceptProduct ? (state.activeProduct.piece - (state.activeProduct.missingPiece + state.activeProduct.occurrencesPiece)) : piece) //toplam sayıları güncelleme
      commit('updatePickingProduct', state.activeProduct) //toplanan ürünler listesini güncelle
      commit('updateProduct', state.activeProduct) //toplanan ürünü güncelleme
      this.commit('user/pickProductForSetInformation')
      Message({ type: 'success', message: i18n.t('UrunBasariylaToplandi') })

    } catch (error) {
      if (error.response.data.Key == "SiparisinizYok" || error.response.data.Key == "ORDER_STATUS_IS_CANCELLED" || error.response.data.Key == "ORDER_PRODUCT_IS_CANCELLED") {
        dispatch('pageOpening')
      }
    }
  },
  //EKSİK ÜRÜN
  async markMissingProduct({ commit, state, dispatch }, missingPieceFromPopup) {
    try {
      state.missingProduct.missingPiece += missingPieceFromPopup
      const response = await Vue.prototype.$axios.post('/api/picking-products/pick', {
        orderID: state.missingProduct.orderID,
        productBarcode: state.missingProduct.productBarcode,
        productID: state.missingProduct.productID,
        orderProductID: state.missingProduct.orderProductID,
        shelfID: state.missingProduct.shelfID,
        piece: missingPieceFromPopup,
        setNo: state.setNo,
        isMissingProduct: true,
        MissingProductReturnCauseID: state.missingProduct.returnReasonID
      })
      commit('updateProduct', state.missingProduct) //ürünün adetini güncelleme
      commit('updateMissingProductCount') //total adet güncelleme
      commit('setMissingProductPopup', false) //eksik ürün popupını kapatma
      Message({ type: 'success', message: i18n.t('UrunEksikListesineEklendi') });

    } catch (error) {
      if (error.response.data.Key == "ORDER_PRODUCT_REJECT_AND_CHANGED_STORE_INFORMATION") {
        Message({ type: 'success', message: i18n.t('UrunUygunOlanDigerMagazalaraGonderilmistir') })
        commit('setMissingProductPopup', false) //eksik ürün popupını kapatma
        dispatch('pageOpening')
      }
      // console.warn(error)
    }
  },
  //ÇIKTI ALMAK İÇİN ÜRÜN LİSTESİNİ ÇEK
  async getProductsForPrint() {
    try {
      const response = await Vue.prototype.$axios.get('/api/picking-products', {
        params: {
          orderId: null,
          shelfId: null,
          dataLimit: 999
        }
      })
      return response.data.model.products
    } catch (error) {
      return error
    }
  },
}

// mutations
const mutations = {
  setActiveShelfId(state, payload) {
    state.activeShelfId = payload
  },
  setActiveShelf(state, payload) {
    let findShelf = state.shelfList.find(item => item.shelfBarcode == payload)
    if (findShelf)
      state.activeShelfId = findShelf.shelfId
    else
      state.activeShelfId = null
  },
  setproductsStats(state, payload) {
    state.productsStats = payload
  },
  setProducts(state, payload) {
    state.products = payload
  },
  updateTotalCounts(state, payload) {
    const total = state.activeProduct.missingPiece + state.activeProduct.occurrencesPiece
    state.activeProduct.occurrencesPiece = state.activeProduct.occurrencesPiece + payload
    state.productsStats.collectedProductCount = state.isAllAcceptProduct ? state.productsStats.collectedProductCount + ((state.activeProduct.piece - total)) : state.productsStats.collectedProductCount + 1
    state.productsStats.remainingProductCount = state.isAllAcceptProduct ? state.productsStats.remainingProductCount - ((state.activeProduct.piece - total)) : state.productsStats.remainingProductCount - 1
  },
  updateProduct(state, payload) {
    let findIndex = state.products.findIndex(item => item.id == payload.id)
    if (findIndex > -1) {
      state.products[findIndex].piece = payload.piece
      state.products[findIndex].occurrencesPiece = payload.occurrencesPiece
      state.products[findIndex].missingPiece = payload.missingPiece
      // console.log(state.products[findIndex])
      if (state.products[findIndex].occurrencesPiece + state.products[findIndex].missingPiece >= state.products[findIndex].piece
        && (this.getters['user/getSettings'].urunToplamaAyar.urunToplamaYontemi != 5)) {
        this.commit('picking/removeProduct', payload) //bütün ürünler toplandıysa raftan sil
      }
      return
    }
    state.products.push(payload)

  },
  removeProduct(state, payload) {
    let findIndex = state.products.findIndex(item => item.productID == payload.productID)
    state.products.splice(findIndex, 1)
    if (state.products.length == 0) {
      this.dispatch('picking/pageOpening', false) //raftaki bütün ürünler bittiyse tekrar süreç başlat
    }
  },
  updateMissingProductCount(state) {
    state.productsStats.missingProductCountInSet = state.productsStats.missingProductCountInSet + 1
    state.productsStats.remainingProductCount = state.productsStats.remainingProductCount - 1
  },
  setActiveProduct(state, payload) {
    state.activeProduct = payload
  },
  setMissingProduct(state, payload) {
    state.missingProduct = payload
  },
  updatePickingProduct(state, payload) {
    let findIndex = state.pickedProducts.findIndex(item => item.barcode == payload.barcode)
    if (findIndex > -1) {
      state.pickedProducts[findIndex] = payload
      return
    }
    state.pickedProducts.push(payload)
  },
  updateOrderList(state, payload) {
    state.orderList = payload
  },
  setActiveOrder(state, payload) {
    state.activeOrder = payload
  },
  setActiveOrderId(state, payload) {
    state.activeOrderId = payload
    if (payload) {
      this.dispatch('picking/getOrderDetail')
      this.dispatch('picking/getProducts')
      this.commit('picking/changeBarcodeType', 1)
    }
  },
  setReturnCauses(state, payload) {
    state.returnCauses = payload
  },
  setMissingProductPopup(state, payload) {
    state.popups.missingPopup = payload
  },
  setEditQuantityPopup(state, payload) {
    state.popups.quantityPopup = payload
  },
  setProductDesiPopup(state, payload) {
    state.popups.desiPopup = payload
  },
  setOtherShelfsPopup(state, payload) {
    state.popups.otherShelfsPopup = payload
  },
  setOtherShelfList(state, payload) {
    state.otherShelfProductList = payload
  },
  changeBarcodeType(state, payload) {
    // 0 raf okut
    // 1 ürün okut
    // 3 sipariş okut
    state.barcodeType = payload
  },

  accordionShowReadProducts(state, payload) {
    state.accordionShowReadProducts = payload
  },

  completePickingAlert() {
    MessageBox.alert('', i18n.t('ToplamaBitti'), {
      center: true,
      showClose: false,
      type: 'success',
      confirmButtonText: this.getters['user/getSettings'].arabaModulAktif ? i18n.t('ArabayiBirak') : i18n.t('SiparisSecimeDon'),
      callback: action => {
        this.getters['user/getSettings'].arabaModulAktif ?
          router.push({ name: 'ToplamaArabasi' }) :
          router.push({ name: 'SiparisSecim' })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}