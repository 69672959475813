<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
const defaultLayout = 'AppLayoutDefault'
export default {
  name: "AppLayout",
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    }
  }
}
</script>
<style lang="scss">
.main-wrapper {
  display: flex;

  @include screen-up(md) {
    padding-top: 75px;
  }
}

.content-wrapper {
  transition: all ease-in-out .25s;
  width: 100%;
  margin-top: 20px;
  height: calc(100vh - 100px);

  @include screen-up(md) {
    padding-left: 235px;
  }

  @include screen-down(md) {
    padding: 0 15px 10px;
    width: 100%;
    min-height: 80vh;
  }
}

.sidebar.collapse-sidebar+.content-wrapper {
  @include screen-up(md) {
    padding-left: 121px;
  }
}
</style>