<template>
  <div class="action-list">
    <template v-if="$store.getters['user/getUser'].type == 1">
      <a class="action-item" @click="goToPicking" v-if="$hasPermission('OrderManagement.ProductPicking.ShowPage')">
        {{ $t('UrunToplama') | textUpparcase }}
      </a>
      <a class="action-item" href="/product-management/counting-list"
        v-else-if="$hasPermission('ProductManagement.Counting.MakeCounting')">
        {{ $t('SayimYap') | textUpparcase }}
      </a>
      <a class="action-item" href="/product-management/where-is-product"
        v-if="$hasPermission('ProductManagement.WhereIsProduct.ShowPage')">
        {{ $t('UrunNerede') | textUpparcase }}
      </a>
      <a class="action-item" href="/product-management/warehouse-product-transfer"
        v-else-if="$hasPermission('ProductManagement.WarehouseProductTransfer.ShowPage')">
        {{ $t('DepolarArasiTransfer') | textUpparcase }}
      </a>
      <a class="action-item" href="/refund-manager/" v-if="$hasPermission('RefundManager.ShowPage')">
        {{ $t('IadeYap') | textUpparcase }}
      </a>
    </template>
    <template v-else>
      <a class="action-item" href="/courier/orders" v-if="$hasPermission('Carrier.ShowPage')">
        {{ $t('DagitimaHazirSiparisler') | textUpparcase }}
      </a>
    </template>
    <a class="action-item news-btn" href="#" @click.prevent="openUpdates">
      <i class="el-icon-magic-stick"></i> {{ $t('SonYenilikler') | textUpparcase }}
    </a>
    <el-dialog :title="$t('SonYenilikler')" :visible.sync="dialogVisible" class="mini-dialog">
      <div class="updates-box">
        <h4>{{ lastUpdate.date }}</h4>
        <ul>
          <li v-for="item in lastUpdate.list" :key="item">{{ item }}</li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import updates from '@/helpers/updates'

export default {
  data() {
    return {
      dialogVisible: false,
    }
  },
  computed: {
    lastUpdate() {
      return updates[0]
    }
  },
  methods: {
    openUpdates() {
      this.dialogVisible = true
      localStorage.setItem('appVersion', this.lastUpdate.version)
    },
    goToPicking() {
      if (this.$store.state.user.setInfo.productPiece) {
        this.$router.push({ name: 'SiparisToplama' })
      } else {
        this.$router.push({ name: 'ToplamaArabasi' })
      }
    }
  },
  mounted() {
    if (!localStorage.getItem('appVersion') || Number(localStorage.getItem('appVersion').split('.').join('')) < Number(this.lastUpdate.version.split('.').join(''))) {
      this.$store.dispatch('app/getLanguageKeys')
      this.openUpdates()
    }
  },
}
</script>

<style lang="scss" scoped>
.action-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-top: 30px;
  margin-top: auto;

  @include screen-between(md, lg) {
    display: none;
  }

  .action-item {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 25px;
    height: 44px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.12px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    background: $color-1;
    display: flex;
    align-items: center;
    gap: 7px;

    &.news-btn {
      background: white;
      color: $color-1;
      border: 1px solid currentColor;
    }

  }
}

.updates-box {
  padding: 0 15px;

  h4 {
    margin-bottom: 12px;
    font-size: 16px;
  }

  ul {
    // list-style-position: inside;
    font-size: 15px;
    padding-left: 22px;
    color: black;

    li {
      margin-bottom: 5px;
      line-height: 1.4;
    }
  }
}
</style>