<template>
  <div class="service-down">
    <h1>503</h1> 
    {{$t('BirSorunOlustuLutfenDahaSonraTekrarDeneyiniz')}}
    <el-button @click="serverControl">Yenile</el-button>
  </div>
</template>

<script>
  export default {
    name: 'ServiceDown',
    methods: {
      async serverControl() {
        try {
          const response = await this.$axios.get('/api/Member/LoginControl')
          await this.$store.dispatch('app/setServerStatusAction', true)
        } catch (error) {
          await this.$store.dispatch('app/setServerStatusAction', false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .service-down {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 22px;
    text-align: center;
    line-height: 1.4;
    h1 {
      font-size: 159px;
      line-height: 1;
      color: $error;
    }
    .el-button {
      margin-top: 30px;
    }
  }
</style>