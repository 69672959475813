const base = [
  {
    path: '/product-management/products',
    name: 'Urunler',
    component: () => import(/* webpackChunkName: "Urunler" */ '@/views/ProductManagement/ProductList.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'Urunler',
      auth:'ProductManagement.Products'
    }
  },
  {
    path: '/product-management/product-detail',
    name: 'UrunDetay',
    component: () => import(/* webpackChunkName: "UrunDetay" */ '@/views/ProductManagement/ProductDetail.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'UrunDetay',
      auth:'ProductManagement.WhereIsProduct.ShowPage'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.barcode || to.query.stockCode || to.query.id) {
        next()
      } else {
        next({name: 'Urunler'})
      }
    }
  },
  {
    path: '/product-management/where-is-product',
    name: 'UrunNerede',
    component: () => import(/* webpackChunkName: "Urunler" */ '@/views/ProductManagement/WhereIsProduct.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'UrunNerede',
      auth:'ProductManagement.WhereIsProduct.ShowPage'
    }
  },
  {
    path: '/product-management/add-product',
    name: 'UrunYerlestirme',
    component: () => import(/* webpackChunkName: "UrunYerlestirme" */ '@/views/ProductManagement/ProductManager/AddProduct.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'UrunYerlestirme',
      auth:'ProductManagement.ShelfProductAdd.ShowPage'
    }
  },
  {
    path: '/product-management/remove-product',
    name: 'UrunCikarma',
    component: () => import(/* webpackChunkName: "UrunCikarma" */ '@/views/ProductManagement/ProductManager/RemoveProduct.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'UrunCikarma',
      auth:'ProductManagement.ShelfProductReduce.ShowPage'
    }
  },
  {
    path: '/product-management/update-product',
    name: 'UrunYerDegistirme',
    component: () => import(/* webpackChunkName: "UrunYerDegistirme" */ '@/views/ProductManagement/ProductManager/UpdateProduct.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'UrunRafiDegistirme',
      auth:'ProductManagement.ShelfProductUpdate.ShowPage'
    }
  },
  {
    path: '/product-management/counting-list',
    name: 'SayimDosyalari',
    component: () => import(/* webpackChunkName: "SayimDosyalari" */ '@/views/ProductManagement/Counting/CountingList.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'SayimListesi',
      auth:'ProductManagement.Counting.ShowList'
    }
  },
  {
    path: '/product-management/counting-detail',
    name: 'SayimDosyaDetay',
    component: () => import(/* webpackChunkName: "SayimDosyaDetay" */ '@/views/ProductManagement/Counting/CountingDetail.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'DosyaDetay',
      auth:'ProductManagement.Counting.ShowList'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.id) {
        next()
      } else {
        next({name: 'SayimDosyalari'})
      }
    }
  },
  {
    path: '/product-management/counting',
    name: 'Sayim',
    component: () => import(/* webpackChunkName: "Sayim" */ '@/views/ProductManagement/Counting/Counting.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'Sayim',
      auth:'ProductManagement.Counting.MakeCounting'
    },
  },
  {
    path: '/product-management/warehouse-product-transfer',
    name: 'DepolarArasiTransfer',
    component: () => import(/* webpackChunkName: "DepolarArasiTransfer" */ '@/views/ProductManagement/WarehouseProductTransfer/List.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'DepolarArasiTransfer',
      auth:'ProductManagement.WarehouseProductTransfer.ShowPage'
    }
  },
  {
    path: '/product-management/warehouse-not-product',
    name: 'DepolarArasiBulunamayanUrunler',
    component: () => import(/* webpackChunkName: "DepolarArasiTransfer" */ '@/views/ProductManagement/WarehouseNotProduct.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'DepolarArasiBulunamayanUrunler',
      auth:'ProductManagement.WarehouseProductTransfer.ShowPage'
    }
  },
  {
    path: '/product-management/warehouse-product-transfer/new',
    name: 'DepolarArasiTransferOlustur',
    component: () => import(/* webpackChunkName: "DepolarArasiTransferOlustur" */ '@/views/ProductManagement/WarehouseProductTransfer/New.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'DepolarArasiTransfer',
      auth:'ProductManagement.WarehouseProductTransfer.ShowPage'
    }
  },
  {
    path: '/product-management/warehouse-product-transfer/pick',
    name: 'DepolarArasiTransferToplama',
    component: () => import(/* webpackChunkName: "DepolarArasiTransferToplama" */ '@/views/ProductManagement/WarehouseProductTransfer/Pick.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'DepolarArasiTransfer',
      auth:'ProductManagement.WarehouseProductTransfer.ShowPage'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.id) {
        next()
      } else {
        next({name: 'DepolarArasiTransfer'})
      }
    }
    
  },
  {
    path: '/product-management/warehouse-product-transfer/control',
    name: 'DepolarArasiTransferKontrol',
    component: () => import(/* webpackChunkName: "DepolarArasiTransferKontrol" */ '@/views/ProductManagement/WarehouseProductTransfer/Control.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'DepolarArasiTransfer',
      auth:'ProductManagement.WarehouseProductTransfer.ShowPage'
    },
    beforeEnter: (to, from, next) => {
      if (to.query.id) {
        next()
      } else {
        next({name: 'DepolarArasiTransfer'})
      }
    }
  },
  {
    path: '/product-management/excess-product-manager',
    name: 'FazlaUrunYonetimi',
    component: () => import(/* webpackChunkName: "FazlaUrunYonetimi" */ '@/views/ProductManagement/ExcessProductManager'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'FazlaUrunYonetimi',
      auth:'ProductManagement.ExcessProductManager.ShowPage'
    }
  },
  //MAL KABUL
  {
    path: '/product-management/goods-accept-manager',
    name: 'MalKabulYonetimi',
    component: () => import(/* webpackChunkName: "MalKabulYonetimi" */ '@/views/ProductManagement/GoodsAccept/GoodsAcceptManager.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'MalKabulYonetimi',
      auth:'ProductManagement.GoodsAcceptManager.ShowPage'
    }
  },
  {
    path: '/product-management/goods-accept-picking',
    name: 'MalKabulOkutma',
    component: () => import(/* webpackChunkName: "MalKabulOkutma" */ '@/views/ProductManagement/GoodsAccept/GoodsAcceptPicking.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'MalKabulOkutma',
      auth:'ProductManagement.GoodsAcceptPicking.ShowPage'
    }
  },
  {
    path: '/product-management/goods-accept-placement',
    name: 'MalKabulUrunYerlestirme',
    component: () => import(/* webpackChunkName: "MalKabulUrunYerlestirme" */ '@/views/ProductManagement/GoodsAccept/Placement.vue'),
    meta: {
      layout: 'AppLayoutNoBg',
      title: 'MalKabulUrunYerlestirme',
      auth:'ProductManagement.GoodsAcceptOrderPlacement.ShowPage'
    }
  },
];

export default base;


