<template>
  <div class="chart-wrapper">
    <div class="chart-header">
      <div class="row">
        <h3>{{ $t('Arabalar') }}</h3>
        <el-button size="mini" v-if="$hasPermission('WarehouseManagement.CarManager.ShowPage')" @click="goToCarManager">
          {{ $t('HepsiniGor') }}
        </el-button>
      </div>
    </div>
    <div class="chart-inner">
      <Transition name="fade" mode="out-in">
        <Pie v-if="chartVisible" :options="chartOptions" :data="chartData" />
        <el-skeleton :rows="5" animated v-else />
      </Transition>
    </div>
    <Transition name="fade" mode="out-in">
      <div class="chart-information pasta-information" v-if="chartVisible">
        <div class="chart-info--item">
          <div class="icon" style="background-color: #FFA7B3;"></div>
          <div class="content">
            <h4>{{ $t('Kullanilan') }}</h4>
            <h5>{{ $store.state.dashboard.carUsingCounts[0] }}</h5>
          </div>
        </div>
        <div class="chart-info--item">
          <div class="icon" style="background-color: #8EEBBF;"></div>
          <div class="content">
            <h4>{{ $t('Bosta') }}</h4>
            <h5>{{ $store.state.dashboard.carUsingCounts[1] }}</h5>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, Colors } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, Colors)
export default {
  components: { Pie },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          colors: {
            enabled: true
          },
          legend: false
        }
      },
    }
  },
  computed: {
    chartVisible() {
      return this.$store.state.dashboard.carChartVisibilty
    },
    chartData() {
      return {
        labels: [this.$t('Kullanilan'), this.$t('Bosta')],
        datasets: [{
          backgroundColor: ['#FFA7B3 ', '#8EEBBF '],
          data: this.$store.state.dashboard.carUsingCounts,
          borderWidth: 0
        }]
      }
    },
  },
  methods: {
    goToCarManager() {
      this.$router.push({ name: 'ArabaListesi' })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  flex: 1;
  min-width: 25%;
  max-width: 33%;

  @include screen-up(xxl) {
    .chart-inner {
      padding: 0 50px 15px;
      text-align: center;

      canvas {
        max-width: 350px;
        margin: 0 auto;
      }
    }
  }

  @include screen-down(md) {
    max-width: 100%;
  }
}
</style>