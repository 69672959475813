<template>
  <div class="welcome-box">
    <h3>{{ $t('Hosgeldiniz') }}!</h3>
    <div class="user">{{ userName }}</div>
    <div class="store-info">
      <div :title="$t('Magaza')"><span><icon-home></icon-home></span>{{ storeName }}</div>
      <div :title="$t('Depo')"><span><icon-home-2></icon-home-2></span>{{ warehoseName }}</div>
    </div>
    <el-button class="cache-btn" type="primary" icon="el-icon-refresh-left" size="mini" circle :title="$t('CacheTemizle')"
      @click="clearCache" v-if="$store.getters['user/getUser'].isTicimaxUser"></el-button>
    <dynamic-actions ref="actions"></dynamic-actions>
    <!-- <div class="footer-actions">
      
      <el-button type="info" round size="mini" icon="el-icon-magic-stick" @click="openUpdates">
        {{ $t('SonYenilikler') }}
      </el-button>
    </div>
    <el-dialog :title="$t('SonYenilikler')" :visible.sync="dialogVisible" class="mini-dialog">
      <div class="updates-box">
        <h4>{{ lastUpdate.date }}</h4>
        <ul>
          <li v-for="item in lastUpdate.list" :key="item">{{ item }}</li>
        </ul>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import DynamicActions from '@/components/Home/DynamicActions.vue';
import IconHome from '@/components/Icons/IconHome.vue';
import IconHome2 from '@/components/Icons/IconHome2.vue';

export default {
  components: {
    DynamicActions,
    IconHome,
    IconHome2
  },
  computed: {
    userName() {
      return this.$store.state.user.user.name
    },
    storeName() {
      return this.$store.state.user.user.storeName
    },
    warehoseName() {
      return this.$store.state.user.user.warehouse
    },

  },
  methods: {
    async clearCache() {
      try {
        this.$loading()
        this.$store.dispatch('dashboard/resetDashboardCache')
        await this.reload()
      } catch (error) {
        console.log(error)
      }
    },
    async reload() {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-box {
  background: var(--box-color);
  border-radius: var(--box-radius);
  padding: 25px 22px;
  padding-bottom: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: $color-1;
  position: relative;
  flex: 1.2 0 450px;

  @include screen-down(xxl) {
    flex: 1 0 350px;
  }

  @include screen-down(md) {
    flex: 1;
    width: 100%;
    padding: 15px;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 5px;
    letter-spacing: -0.22px;
    font-weight: bold;
  }

  .user {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.18px;
  }

  .store-info {
    position: absolute;
    right: 20px;
    top: 35px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.14px;
    color: #8993A4;
    display: grid;
    gap: 6px;

    @include screen-down(md) {
      top: 15px;
    }

    >div {
      display: flex;
      align-items: center;
      gap: 5px;

      span {
        background: #8993A4;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        svg {
          height: 12px;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  .footer-actions {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .el-button {
      margin: 0;
    }
  }

  .cache-btn {
    position: absolute;
    right: 3px;
    top: 3px;
  }
}
</style>