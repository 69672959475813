import Vue from 'vue'


const base = [
  {
    path: '/setup',
    name: 'KurulumSihirbazi',
    component: () => import(/* webpackChunkName: "KurulumSihirbazi" */ '@/views/Setup/Index.vue'),
    meta: {
      title: 'KurulumSihirbazi',
      layout: 'SetupLayout',
    },
    beforeEnter: (to, from, next) => {
      if (Vue.$cookies.get('userType') == 999) {
        next()
      } else {
        next({name: 'Anasayfa'})
      }
    }
  }
];

export default base;
