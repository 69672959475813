import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "./en";
import tr from "./tr";
Vue.use(VueI18n);

const messages = {
  'tr': tr,
  'en': en
};

export default new VueI18n({
  locale: localStorage.getItem("lang") || 'tr', // set locale
  fallbackLocale: 'tr',
  messages, // set locale messages
});