import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import '@/assets/scss/element-variables.scss';
import i18n from "@/locales/index";
import VueCookies from 'vue-cookies';
import '@/assets/scss/main.scss'
import  "@/helpers/filters";
import { axiosInstance } from '@/plugins/axios'
import { hasPermission } from '@/plugins/permission'
import mixins from '@/helpers/mixins/mixin'

Vue.mixin(mixins)
Vue.prototype.$axios = axiosInstance;
Vue.prototype.$hasPermission = hasPermission

Vue.use(VueCookies);
Vue.use(ElementUI, {i18n: (key, value) => i18n.t(key, value)})

Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
