<template>
  <div class="compare-box-list">
    <h3><span></span> {{ $t('SizeOzel') }}</h3>
    <div class="compare-box-item">
      <div class="day-box">
        <div class="count">{{ orderCounts.yesterdayProductTotal }}</div>
        <span v-html="$t('DunToplananUrunSayisi')"></span>
      </div>
      <div class="day-box"
        :class="orderCounts.todayProductTotal >= orderCounts.yesterdayProductTotal ? 'to-up' : 'to-down'">
        <div class="count">{{ orderCounts.todayProductTotal }}</div>
        <span v-html="$t('BugunToplananUrunSayisi')"></span>
      </div>
    </div>
    <div class="compare-box-item">
      <div class="day-box">
        <div class="count"> {{ controlCounts.yesterdayCount }} </div>
        <span v-html="$t('OncekiGunKaliteKontrolSayisi')"></span>
      </div>
      <div class="day-box" :class="controlCounts.todayCount >= controlCounts.yesterdayCount ? 'to-up' : 'to-down'">
        <div class="count">{{ controlCounts.todayCount }} </div>
        <span v-html="$t('BugunKaliteKontrolSayisi')"></span>
      </div>
    </div>

  </div>
</template>

<script>
// import IconUser from '../Icons/IconUser.vue';

export default {
  components: {

  },
  computed: {
    orderCounts() {
      return this.$store.state.adminDashboard.userCounts.order
    },
    controlCounts() {
      return this.$store.state.adminDashboard.userCounts.control
    }
  }
}
</script>

<style lang="scss" scoped>
.compare-box-list {
  background: var(--box-color);
  border-radius: var(--box-radius);
  display: grid;
  gap: 10px;
  padding: 25px 20px;
  align-content: flex-start;

  h3 {
    font-size: 18px;
    letter-spacing: -0.18px;
    color: $color-1;
    border-bottom: 1px solid #E5E8EB;
    padding-bottom: 11px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    span {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #fff;
      margin-right: 8px;
      color: #8993a4;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 15px;
      }
    }
  }

  .compare-box-item {
    display: flex;
    gap: 15px;
  }

  .day-box {
    padding: 25px 30px;
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 500;
    background: #fff;
    border-radius: 12px;
    letter-spacing: -0.12px;
    color: $color-1;

    @include screen-between(lg, xxl) {
      padding: 20px 10px;
    }

    .count {
      font-size: 37px;
      font-weight: 800;
      letter-spacing: -1.11px;
      color: $color-1;
    }

  }

}
</style>
<style lang="scss">
.compare-box-list {
  b {
    display: block;
  }
}
</style>